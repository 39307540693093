<div class="home-slider owl-theme owl-carousel">
    <div class="slider-item slider-item-img">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape">
                            <img src="assets/img/home-one/home-slider/c19.png" alt="Shape">
                        </div>
                        <h1 style="max-width: 500px;">COVID-19</h1>
                        <p style="max-width: 400px;">
                            La unidad de aislamiento de COVID-19 Se posiciona como la unidad privada más grande del país para atender pacientes con esta enfermedad, 
                            con un equipo médico de primer nivel, personal de enfermería capacitado y habitaciones acondicionadas 
                            para brindar al paciente todo lo que necesita para su recuperación. 
                        </p>

                        <div class="common-btn">
                            <!-- <a routerLink="/appointment">Get Appointment</a> -->
                            
                            <a class="cmn-btn-right" routerLink="/covid19">Leer más</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item slider-item-img">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape-two">
                            <img src="assets/img/home-one/home-slider/asegura.png" alt="Shape">
                        </div>
                        <h1 style="max-width: 500px;">ALIANZA CON ASEGURADORAS</h1>
                        <p style="max-width: 400px;">
                            Durante nuestros años de servicio hemos logrado alianzas con importantes empresas de seguros Nacionales e Internacionales.
                        </p>

                        <div class="common-btn"><!-- 
                            <a routerLink="/appointment">Get Appointment</a> -->
                            <a class="cmn-btn-right" routerLink="/sobrenosotros">Leer más</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- 
    <div class="slider-item slider-item-img">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape-three">
                            <img src="assets/img/home-one/home-slider/3.png" alt="Shape">
                        </div>
                        <h1 style="max-width: 500px;">SEAFARERS</h1>
                        <p style="max-width: 400px;">Una novedosa área donde se brinda lo necesario para el pre-embarque a marinos. </p>

                        <div class="common-btn">
                            <a class="cmn-btn-right" [routerLink]="['/services-details']" [queryParams]="{servicio: 'SEAFARERS'}">Leer más</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>


<!-- <div class="emergency-area">
    <div class="container">
        <div class="row emergency-bg">
            <div class="col-sm-6 col-lg-4">
                <div class="emergency-item">
                    <i class="icofont-ui-call"></i>
                    <div class="emergency-inner">
                        <h3>Teléfonos</h3>
                        <p>+504 2606-0024</p>
                        <p>+504 9541-0174 <span class="icofont-brand-whatsapp"></span></p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="emergency-item">
                    <i class="icofont-location-pin"></i>
                    <div class="emergency-inner">
                        <h3>Dirección</h3>
                        <p><a style="color: white;" target="_blank" href="https://goo.gl/maps/x5zoixSRxDdub4gL8">La Lima Cortés, Frente a Zona Americana</a></p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="emergency-item">
                    <i class="icofont-ui-clock"></i>
                    <div class="emergency-inner">
                        <h3>Horarios</h3>
                        <p>Abierto las 24 horas</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="counter-area ">
    <div class="container">
        <div class="row counter-bg">
            <div class="col-sm-6 col-md-3 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-patient-bed"></i>
                    <h3 class="counter">+100</h3>
                    <p>Camas</p>
                </div>
            </div>

            <div class="col-sm-6 col-md-3 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-like"></i>
                    <h3><span class="counter">100%</span></h3>
                    <p>Calidad Garantizada</p>
                </div>
            </div>

            <div class="col-sm-6 col-md-3 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-doctor-alt"></i>
                    <h3 class="counter">+100</h3>
                    <p>Médicos y Enfermeras</p>
                </div>
            </div>

            <div class="col-sm-6 col-md-3 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-badge"></i>
                    <h3 class="counter">70</h3>
                    <p>Años de Experiencia</p>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img src="assets/img/home-one/SobreNosotros.jpg" alt="About">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    <img src="assets/img/home-one/home-bg5.png" alt="About">
                    <h2>Sobre Nosotros</h2>
                    <p>
                        Inaugurado en 1951 por la Tela Railroad Company para brindar atención médica a sus empleados y
                        dependientes, fue adquirido a mediados del año 2003 por Hospital La Lima, S.A. de C.V. Desde sus
                        inicios como hospital privado, La Lima Medical Center ha sido sometido a constantes
                        remodelaciones y hasta el dia de hoy gracias a su renombrado equipo de profesionales Médicos,
                        dotados de la más moderna tecnología en salud, y de
                        personal altamente calificado en las diferentes áreas, les ha permitido crecer en forma
                        acelerada.
                    </p>

                    <ul>
                        <li><i class="icofont-check-circled"></i> Abierto las 24 horas</li>
                        <li><i class="icofont-check-circled"></i> Alianzas con Aseguradoras</li>
                        <li><i class="icofont-check-circled"></i> Unidad de Aislamiento de COVID-19</li>
                    </ul>
                    <a routerLink="/sobrenosotros">Leer más</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Seccion de videos -->
 <div class="video-wrap">
    <div class="container-fluid p-0">
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="video-area ">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item ">
                                    <a href="https://www.youtube.com/watch?v=03LMxykGIfo" class="popup-youtube"><i
                                            class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Vídeo Institucional</h3>
                                        <p>Actualmente es considerado como el hospital con mayor capacidad hospitalaria instalada 
                                            con todos los servicios de diagnóstico de un centro médico moderno, dotado de tecnología de 
                                            última generación.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=MR2Cg7T3uuk" class="popup-youtube"><i
                                            class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Testimonio</h3>
                                        <p>Te contamos una parte de la historia de la vida de Ramon Salguero, que con la 
                                            ayuda de La Lima Medical Center pudo recuperar su salud y continuar el sueño de 
                                            tener su propia empresa. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=3hq7LS2EQJo" class="popup-youtube"><i
                                            class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Seafarers</h3>
                                        <p>Una novedosa area donde se brinda el acompañamiento a los Marinos para realizar examenes médicos de Pre Embarque 
                                            para garantizar que el personal que se embarca sea altamente calificado y goce de un optimo estado de salud. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

           <!--  DESACTIVADO comentar
                <div class="tab-pane fade" id="pills-icu" role="tabpanel" aria-labelledby="pills-icu-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i
                                            class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>CCU & ICU</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse
                                            ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel
                                            facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

            <div class="tab-pane fade" id="pills-doctor" role="tabpanel" aria-labelledby="pills-doctor-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i
                                            class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Our Doctors</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                            tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse
                                            ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel
                                            facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> DESACTIVADO  comentar -->
        </div>
    </div>
    <div class="container">
        <ul class="video-nav nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item video-nav-item"><a class="nav-link active" id="pills-home-tab" data-toggle="pill"
                    href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Vídeo Institucional</a></li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-profile-tab" data-toggle="pill"
                    href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Testimonio</a>
            </li>

            <!-- <li class="nav-item video-nav-item"><a class="nav-link" id="pills-contact-tab" data-toggle="pill"
                    href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Seafarers</a></li> -->

        <!-- DESATIVADO comentar
                 <li class="nav-item video-nav-item"><a class="nav-link" id="pills-icu-tab" data-toggle="pill"
                    href="#pills-icu" role="tab" aria-controls="pills-icu" aria-selected="false">CCU & ICU</a></li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-doctor-tab" data-toggle="pill"
                    href="#pills-doctor" role="tab" aria-controls="pills-doctor" aria-selected="false">Doctors</a></li> 
        DESACTIVADO comentar -->
        </ul>
    </div>
</div> 
<!-- Fin de seccion videos -->
<div CLASS= "pt-100">

</div>

<!-- Seccion de videos -->
<div class="width: 100% height: 560px
margin-bottom: 30px" >
    <img src="assets//img/home-two/70.png">
   
</div> 
<!-- Fin de seccion videos -->




<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Preguntas Frecuentes</h2>
                </div>
                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow fadeInUp" data-wow-delay=".3s">
                            <a class="link"><div>¿Cuáles son los horarios de los médicos Generales y Especialistas?</div></a>
                            <p>Para conocer el horario haz clic al siguiente enlace: <span class="ref" (click)="goto('/medicos')">Directorio</span> o puedes llamar al <span class="ref" (click)="goto('tel:+50426060024')">+504 2606-0024</span> extensión #1</p>
                        </li>
                        <li class="wow fadeInUp" data-wow-delay=".4s">
                            <a class="link"><div>¿Cómo puedo cotizar exámenes estudios de Laboratorio?</div></a>
                            <p>Para cotizar los exámenes de laboratorio puedes llamar al <span class="ref" (click)="goto('tel:+50426060024')">+504 2606-0024</span> extensión #2</p>
                        </li>
                        <li class="wow fadeInUp" data-wow-delay=".5s">
                            <a class="link"><div>¿Cómo puedo cotizar exámenes estudios de Rayos X, Tomografías, Resonancias Magnéticas o Ultrasonidos?</div></a>
                            <p>Para cotizar nuestros estudios de Radiología puedes llamar al <span class="ref" (click)="goto('tel:+50426060024')">+504 2606-0024</span> extensión #3</p>
                        </li>
                        <li class="wow fadeInUp" data-wow-delay=".6s">
                            <a class="link"><div>¿Cómo puedo cotizar una Cirugía General?</div></a>
                            <p>Para cotizar el valor aproximado de una cirugía puedes llamar al <span class="ref" (click)="goto('tel:+50426060024')">+504 2606-0024</span> o mandar un correo electrónico a <span class="ref" (click)="goto('mailto:rmolina@llmc.hn')">rmolina@llmc.hn</span> para poder ayudarte.</p>
                        </li>
                        <li class="wow fadeInUp" data-wow-delay=".7s">
                            <a class="link"><div>¿Cómo puedo obtener información de una Cirugía Bariátrica o un Paquete Nutricional CIO5?</div></a>
                            <p>Para cotizar el valor aproximado de una cirugía bariátrica para perder peso o un paquete nutricional puedes llamar al <span class="ref" (click)="goto('tel:+50426060024')">+504 2606-0024.</span></p>
                        </li>
                        <li class="wow fadeInUp" data-wow-delay=".7s">
                            <a class="link"><div>¿Cómo puedo cotizar un Plan Médico?</div></a>
                            <p>Para cotizar el valor de un plan médico puedes llamar al <span class="ref" (click)="goto('tel:+50426060024')">+504 2606-0024.</span> adicional a ello debes proporcionar la edad y genero de las personas interesadas en tomar el plan médico.</p>
                        </li>
                        <li class="wow fadeInUp" data-wow-delay=".7s">
                            <a class="link"><div>¿Como puedo ser parte del equipo de colaboradores de La Lima Medical Center?</div></a>
                            <p>Puedes mandar tu hoja de vida actualizada a <span class="ref" (click)="goto('mailto:servicioalcliente@llmc.hn')">servicioalcliente@llmc.hn</span></p>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
 
    </div>
</section>

<!-- 
<section class="services-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Hospital Services</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Expert Doctor</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Expert Doctor</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-prescription"></i>
                        <h3>Diagnosis</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-prescription"></i>
                        <h3>Diagnosis</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-patient-file"></i>
                        <h3>Pathology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-patient-file"></i>
                        <h3>Pathology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-tooth"></i>
                        <h3>Dental Care</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-tooth"></i>
                        <h3>Dental Care</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Cardiology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Cardiology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="expertise-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expertise</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="expertise-item">
                    <div class="row">
                        <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                            <a routerLink="/blog-details">
                                <div class="expertise-inner">
                                    <i class="icofont-doctor-alt"></i>
                                    <h3>Certified Doctors</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                            <a routerLink="/blog-details">
                                <div class="expertise-inner">
                                    <i class="icofont-stretcher"></i>
                                    <h3>Emergency</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                            <a routerLink="/blog-details">
                                <div class="expertise-inner">
                                    <i class="icofont-network"></i>
                                    <h3>Teachnology</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                            <a routerLink="/blog-details">
                                <div class="expertise-inner">
                                    <i class="icofont-ambulance-cross"></i>
                                    <h3>Ambulance</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="expertise-item">
                    <div class="expertise-right">
                        <img src="assets/img/home-one/home-bg6.jpg" alt="Expertise">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


    <div class="container">
        <ul class="video-nav nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item video-nav-item"><a class="nav-link active" id="pills-home-tab" data-toggle="pill"
                    href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Hospital
                    Introduction</a></li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-profile-tab" data-toggle="pill"
                    href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Pharmacy</a>
            </li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-contact-tab" data-toggle="pill"
                    href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Reasearch &
                    Lab</a></li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-icu-tab" data-toggle="pill"
                    href="#pills-icu" role="tab" aria-controls="pills-icu" aria-selected="false">CCU & ICU</a></li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-doctor-tab" data-toggle="pill"
                    href="#pills-doctor" role="tab" aria-controls="pills-doctor" aria-selected="false">Doctors</a></li>
        </ul>
    </div>
</div>

<section class="doctors-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Meet Our Doctors</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/home-one/doctor/1.jpg" alt="Doctor">
                        <a routerLink="/appointment">Get Appointment</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dr. Babatunde</a></h3>
                        <span>Neurosurgeon</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/home-one/doctor/2.jpg" alt="Doctor">
                        <a routerLink="/appointment">Get Appointment</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dr. Addision Smith</a></h3>
                        <span>Neurosurgeon</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/home-one/doctor/3.jpg" alt="Doctor">
                        <a routerLink="/appointment">Get Appointment</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dr. Sarah Tylor</a></h3>
                        <span>Dental Surgeon</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="doctor-btn">
            <a routerLink="/doctor">See All</a>
        </div>
    </div>
</section>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest Blogs</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg11.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">In this hospital there are special surgeon.</a></h3>
                        <p>Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut
                            labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a>
                            </li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg12.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">World AIDS Day, designated on 1 December.</a></h3>
                        <p>Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut
                            labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a>
                            </li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg13.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">More than 80 clinical trials launch to test coronavirus.</a>
                        </h3>
                        <p>Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut
                            labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a>
                            </li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->