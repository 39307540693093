import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiciosService } from 'src/app/services/servicios.service';

@Component({
  selector: 'app-services-details',
  templateUrl: './services-details.component.html',
  styleUrls: ['./services-details.component.scss']
})
export class ServicesDetailsComponent implements OnInit {

  servicio = null;
  serviceName = "";

  constructor(private route: ActivatedRoute, public servicios: ServiciosService, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      if (params) {
        this.getServiceInfo(params.get('servicio'));
      } else {
        this.router.navigateByUrl("/servicios");
      }
    });
  }

  getServiceInfo(serviceName) {
    this.serviceName = serviceName;
    this.servicio = this.servicios.Servicios.filter(i => i.nombre === serviceName)[0];

    if (this.servicio === null || this.servicio === undefined) {
      this.router.navigateByUrl("/servicios");
    }
  }

}
