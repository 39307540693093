import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {
  Servicios = [{
    id: 1,
    nombre: 'RADIOLOGÍA',
    descripcion: 'Nuestro departamento cuenta con lo más avanzado en tecnología de radiología e imagen con los que se practican estudios tanto diagnósticos como terapéuticos, invasivos y no invasivos. Resonancia Magnética 1.5 Tesla, Digitalizador de imágenes, Ultrasonido 3D & 4D, Tomografía Volumétrica 64 canales, Mamografía, Radiología convencional (Rayos X).',
    imagen: 'assets/img/RADIOLOGIA445X351.jpg',
    servi: [ 
      {
        id: 1, 
        img: 'assets/img/ULTRASONIDO4D445X351.jpg', 
        nom:'Ultrasonido'},
      { 
        id: 2,
        img: 'assets/img/RAYOSX445X351.jpg',
        nom:'Rayos X'},
      { 
        id: 3,
        img: 'assets/img/TOMOGRAFIA445X351.jpg',
        nom:'Tomografias'},
      { 
        id: 4,
        img: 'assets/img/RESONANCIAMAGNETICA445X351.jpg',
        nom:'Resonancia Magnetica'},
      {
        id: 5,
        img: 'assets/img/MAMOGRAFIA445X351.jpg',
        nom:'Mamografia'}
          ],
    
    descipcionResumen: 'Nuestro departamento cuenta con lo más avanzado en tecnología de radiología e imagen.',
    icono: 'icofont-xray',
  },{
    id: 2,
    nombre: 'LABORATORIO',
    descripcion: 'Nuestro laboratorio está dotado de los medios suficientes para prestarlos servicios propios del ámbito hospitalario. Bioquímica Clínica, Hematología, Inmunología, Endocrinología, Oncología, Infectología, Microbiología y parasitología, Toxicología, Uroanálisis, Exfoliativa, Coproanálisis, Banco de sangre.',
    imagen: 'assets/img/LABORATORIO445X351.jpg',
    servi: [ {id: 1, img: 'assets/img/TOMADEMUESTRAS445X351.jpg',
    nom:'Toma de Muestras'}],
    descipcionResumen: 'Está dotado de los medios suficientes para prestarlos servicios propios del ámbito hospitalario.',
    icono: 'icofont-microscope',
  },
  {
    id: 6,
    nombre: 'SALA DE EMERGENCIA',
    descripcion: 'LLMC cuenta con la Sala de Emergencias más grande y moderna del país, con capacidad de poder atender 16 casos simultáneos, cuando se requiere. Dotada de una sala de trauma y reanimación cardiopulmonar, cirugía menor, preclínica y 7 unidades de observación.',
    servi:[],
    imagen: 'assets/img/SALADEEMERGENCIAS445X351.jpg',
    descipcionResumen: 'La Sala de Emergencias más grande y moderna del país, con capacidad para atender 16 casos simultáneos.',
    icono: 'icofont-ambulance-cross',
  },
  
  {
    id: 10,
    nombre: 'SALA DE MATERNIDAD',
    descripcion: 'El área de labor y parto es un área en donde se vigila la evolución de las pacientes embarazadas que se encuentran en trabajo de parto, la parte más importante de esta área es el recurso humano conformado por nuestros médicos y enfermeras que están adecuadamente capacitados para tomar las mejores decisiones para garantizar la salud de nuestros pacientes. ',
    servi:[],
    imagen: 'assets/img/LABORYPARTOX351.jpg',
    descipcionResumen: 'Esta área dispone de los equipos e instrumentos adecuados para atender a las pacientes al momento del parto.',
    icono: 'icofont-baby',
  },
  {
    id: 19,
    nombre: 'HOSPIPLAN',
    descripcion: 'Es un plan médico prepagado con el propósito de ofrecer una mejor alternativa en salud a costos accesibles, para gozar de salud y protección para toda la familia. Contamos con los siguientes planes: PLAN PLUS, PLAN CLASSIC, PLAN DE ATENCIÓN PRIMARIA Y EMERGENCIAS, PLAN EMPRESARIAL.',
    servi:[],
    imagen: 'assets/img/HOSPIPLAN445X351.jpg',
    descipcionResumen: 'Es un plan médico prepagado con el propósito de ofrecer una mejor alternativa en salud a costos accesibles, para gozar de salud y protección para toda la familia.',
    icono: 'icofont-hospital',
  },
    
  //   {
  //   id: 3,
  //   nombre: 'SALA CUNA',
  //   descripcion: 'Cada una de las áreas dispone de los equipos e instrumentos adecuados para el cuidado del recién nacido. Nuestras salas cuentan con: 10 bacinetes, 4 incubadoras abiertas y cerradas, Lámparas, Fototerapia, Sala de parto, Cuidados intensivos neonatales',
  //   servi:[],
  //   imagen: 'assets/img/SALACUNA445X351.jpg',
  //   descipcionResumen: 'Cada una de las áreas dispone de los equipos e instrumentos adecuados para el cuidado del recién nacido.',
  //   icono: 'icofont-baby-trolley',
  // },
 
  
  // {
  //   id: 7,
  //   nombre: 'CENTRO DE HEMODINÁMIA',
  //   descripcion: 'Este centro consiste en un laboratorio de cateterismo compuesto porangiografía de alta definición, el que es utilizado para el diagnóstico y tratamiento de la enfermedad coronaria, procedimientos útiles para la prevención y manejo del infarto cardíaco, entre otras utilidades está el manejo de la obstrucción de las arterias en pacientes con insuficiencia vascular periférica.  ',
  //   servi:[],
  //   imagen: 'assets/img/HEMODINAMIA445X351.jpg',
  //   descipcionResumen: 'Consiste en un laboratorio de cateterismo compuesto por angiografía de alta definición.',
  //   icono: 'icofont-ui-love-add',
  // },
  // {
  //   id: 8,
  //   nombre: 'SEAFARERS',
  //   descripcion: 'Una novedosa área donde se brinda lo necesario para el pre-embarque a marinos. Se realizan Exámenes de: Laboratorio, Rayos X, Exámenes Psicológicos, Atención Médica. Evaluación Médica con Certificación Internacional, Somos un centro que cumple todos los estándares en atención medica de marinos, únicos en Centro América. ',
  //   servi:[],
  //   imagen: 'assets/img/sea.jpeg',
  //   descipcionResumen: 'Una novedosa área donde se brindan todos los exámenes necesarios para el pre-embarque a marinos.',
  //   icono: 'icofont-ship',
  //   desc: 'Para mayor información llamar al: 9518-9853. El único Hospital en Centro América con certificación internacional para la evaluación médica de Embarque para Marinos por parte de ACREDITAS GLOBAL'
  // },
  {
    id: 9,
    nombre: 'UNIDAD DE CUIDADOS INTENSIVOS PARA ADULTOS',
    descripcion: 'Contamos con personal médico especializado, personal profesional de Enfermería y técnicos capacitados. Servicio de terapia intermedia, 3 Unidades equipadas, Sala de Aislamiento. ',
    servi:[],
    imagen: 'assets/img/UCI445X351.jpg',
    descipcionResumen: 'Contamos con personal médico, de Enfermería y técnicos altamente calificado y especializados.',
    icono: 'icofont-heartbeat',
  },
  {
    id: 11,
    nombre: 'UNIDAD DE CIRUGÍA ESTÉTICA Y RECONSTRUCTIVA',
    descripcion: 'La Unidad de Cirugía Estética del Hospital La Lima Medical Center, ofrece un tratamiento integral a los requerimientos modernos de procedimientos de cirugía estética. Tipos de procedimientos: Suspensión del rostro, Mentoplastía, Rinoplastia, Implantes Mamarios, Mastopexia, 	Reducción Mamaria, Abdominoplastía, Liposucción, Blefaroplastia.',
    servi:[],
    imagen: 'assets/img/CIRUGIAESTETICA445X351.jpg',
    descipcionResumen: 'Ofrecemos un tratamiento integral a los requerimientos modernos de procedimientos de cirugía estética.',
    icono: 'icofont-doctor',
  },
  {
    id: 12,
    nombre: 'UNIDAD DE ENDOSCOPIA & COLONOSCOPIA',
    descripcion: 'Esta unidad ofrece todos los servicios necesarios para la prevención, el diagnóstico, el tratamiento y la rehabilitación de enfermedades del sistema digestivo. A través del personal médico mejor calificado, la tecnología más avanzada y procesos de atención de excelencia, el CIED busca atender las demandas de salud digestiva de la población.',
    servi:[],
    imagen: 'assets/img/ENDOSCOPIAYCOLONOSCOPIA445X351.jpg',
    descipcionResumen: 'Servicios necesarios para la prevención, el diagnóstico y el tratamiento de enfermedades del sistema digestivo.',
    icono: 'icofont-prescription',
  },
  
 
  
  {
    id: 13,
    nombre: 'PLAN CIGÜEÑA AZUL',
    descripcion: 'Es un plan de maternidad que le ofrece facilidades de pago en parto por Cesárea y por Parto Normal. Inscripción con tan solo 20% sobre el plan seleccionado, Cuotas de acuerdo con la disponibilidad de la afiliada, Cobertura del plan solamente para personas que no tiene seguro médico, La vigencia del plan es de 9 meses, Fecha máxima de inscripción hasta el 5to mes de embarazo. ',
    servi:[],
    imagen: 'assets/img/PLANCIGUENAAZUL445X351.jpg',
    descipcionResumen: 'Cigueña azúl es un plan de maternidad que le ofrece facilidades de pago en parto por Cesárea y por Parto Normal. La vigencia del plan es de 9 meses, Fecha máxima de inscripción hasta el 5to mes de embarazo. ',
    icono: 'icofont-safety-pin',
  },

  {
    id: 14,
    nombre: 'TERAPIA FISICA Y REHABILITACIÓN',
    descripcion: 'Ofrece una atención completa que tiene como finalidad la recuperación integral del paciente abordando sus necesidades físicas, psicológicas, médicas, laborales y sociales. La rehabilitación física está diseñada para atender las necesidades específicas de cada paciente.',
    servi:[],
    imagen: 'assets/img/FISIOTERAPIA445X351.jpg',
    descipcionResumen: 'Ofrece una atención completa que tiene como finalidad la recuperación integral del paciente abordando sus necesidades físicas, psicológicas, médicas, laborales y sociales.',
    icono: 'icofont-gym',
  },
  // {
  //   id: 15,
  //   nombre: 'CENTRO DE GINECOLOGÍA ESTÉTICA Y RECONSTRUCTIVA',
  //   descripcion: 'Esta nueva área en LLMC, que en los últimos años ha cobrado mayor interés entre nuestras pacientes, que conscientes de mejorar su calidad de vida, buscan nuevos tratamientos frente a diferentes temas antes desconocidos, es importante que la mujer esté conforme y segura con su intimidad y este centro pone a disposición procedimientos estéticos con medicina avanzada al alcance de tus manos. ',
  //   servi:[],
  //   imagen: 'assets/img/CENTROGINECOLOGICO445X351.jpg',
  //   descipcionResumen: 'Ponemos a disposición procedimientos estéticos con medicina avanzada al alcance de tus manos para mejorar el estilo de vida.',
  //   icono: 'icofont-female',
  // },
  {
    id: 16,
    nombre: 'UNIDAD DE ENFERMEDAD VASCULAR PERIFÉRICA Y PIÉ DIABÉTICO',
    descripcion: 'El objetivo principal es evitar que nuestros pacientes lleguen al extremo de tener que decidir entre una  amputación o arriesgar su vida. Actualmente cuenta con una Sala de Recepción, 2 Salas de espera, Preclínica, Quirófano, Laboratorio Vascular y Consultorios. ',
    servi:[],
    imagen: 'assets/img/PIEDIABETICO445X351.jpg',
    descipcionResumen: 'Evitamos que los pacientes decidan entre una amputación o arriesgar su vida.',
    icono: 'icofont-gnome',
  },
  {
    id: 5,
    nombre: 'SALAS DE HOSPITALIZACIÓN PARA NIÑOS Y ADULTOS',
    descripcion: 'La Lima Medical Center cuenta con 60 habitaciones privadas y 4 suites (Incluye sala de espera privada); cada una de ellas con todas las comodidades: Wi-Fi, TV por cable, aire acondicionado, teléfono, caja de seguridad, y baño privado. ',
    servi:[],
    imagen: 'assets/img/HOSPITALIZACION445X351.jpg',
    descipcionResumen: 'Contamos con 60 habitaciones privadas y 4 suites.',
    icono: 'icofont-patient-bed',
  },
  // {
  //   id: 17,
  //   nombre: 'CENTRO DE CONVALECENCIA',
  //   descripcion: 'Un concepto novedoso en el cuidado post operatorio para lograr una rehabilitación acelerada del paciente, con independencia de su familia. Además, se cuenta con un hermoso entorno ecológico en el hospital.',
  //   servi:[],
  //   imagen: 'assets/img/CENTRODECONVALECENCIA445X351.jpg',
  //   descipcionResumen: 'Un concepto novedoso en el cuidado post operatorio para lograr una rehabilitación acelerada del paciente, con independencia de su familia.',
  //   icono: 'icofont-ui-handicapped',
  // },
  // {
  //   id: 18,
  //   nombre: 'CENTRO DE CIRUGÍA ATROSCOPICA',
  //   descripcion: 'Este Centro te brinda servicios de primer nivel a través de profesionales con mucha experiencia en el área lo cual permite realizar operaciones de una forma menos invasiva, minimizando los daños y cicatrices provocados por la operación, lo cual mejora los tiempos de recuperación.',
  //   servi:[],
  //   imagen: 'assets/img/UNIDADDEARTROSCOPIA445X351.jpg',
  //   descipcionResumen: 'Realizamos operaciones de una forma menos invasiva, minimizando las cicatrices de la operación, lo cual mejora los tiempos de recuperación.',
  //   icono: 'icofont-crutch',
  // },
 
 
  // {
  //   id: 20,
  //   nombre: 'UNIDAD DE AISLAMIENTO COVID-19',
  //   descripcion: 'Esta nueva área surge debido a los altos casos de COVID-19 en el territorio nacional, y se posiciona como la unidad privada más grande del país para atender pacientes con esta enfermedad, con un equipo médico de primer nivel, personal de enfermería capacitado y habitaciones acondicionadas para brindar al paciente todo lo que necesita para su recuperación. ',
  //   servi:[],
  //   imagen: 'assets/img/UNIDADDEAISLAMIENTOCOVID19445X351.jpg',
  //   descipcionResumen: 'La unidad privada más grande del país para atender pacientes con esta enfermedad.',
  //   icono: 'icofont-folkd',
  // },
 
  {
    id: 21,
    nombre: 'HELIPUERTO',
    descripcion: 'Actualmente en La Lima Medical Center contamos con un Helipuerto, necesario en caso de emergencia para pacientes que son traídos de lugares pocos accesibles, esta área cuenta con el espacio y medidas necesarias para brindar este servicio de la mejor manera.',
    servi:[],
    imagen: 'assets/img/HELIPUERTO.PNG',
    descipcionResumen: 'Actualmente en La Lima Medical Center contamos con un Helipuerto.',
    icono: 'fas fa-helicopter',
  },
  {
    id: 25,
    nombre: 'SALUD MENTAL',
    descripcion: 'Tratamiento para lograr el bienestar emocional, psicológico y social del paciente.',
    servi:[],
    imagen: 'assets/img/SALUDMENTAL445X351.jpg',
    descipcionResumen: 'Bienestar emocional, psicológico y social.',
    icono: 'icofont-brain-alt',
  },
  
  {
    id: 24,
    nombre: 'SALUD BUCAL',
    descripcion: 'La salud de los dientes, encías y el sistema estomatognático que nos permite sonreír, hablar y masticar es muy importante, las enfermedades bucodentales son las enfermedades no transmisibles más comunes, y afectan a las personas durante toda su vida, causando dolor, molestias, desfiguración e incluso tener un desenlace grave.',
    servi:[],
    imagen: 'assets/img/SALUDBUCAL445X351.jpg',
    descipcionResumen: 'Salud de los dientes, encías y el sistema estomatognático.',
    icono: 'icofont-tooth',
  },
  {
    id: 24,
    nombre: 'UNIDAD DE SALUD INTEGRAL',
    descripcion: 'Control de enfermedades crónicas, desequilibrio metabólico, patologías cardiacas, trastornos de nutrición, tratamiento y prevención de la obesidad.',
    servi:[],
    imagen: 'assets/img/CARDIOMETABOLICA445X351.jpg',
    descipcionResumen: 'Control de enfermedades crónicas.',
    icono: 'icofont-heart-beat-alt',
  },
  {
    id: 4,
    nombre: 'SALA DE OPERACIONES',
    descripcion: 'Cuenta con 3 quirófanos dotados con equipo moderno y tecnología de punta, donde se realiza cirugía convencional, Cirugía Laparoscópica (General y Gineco - Obstétrica) y Cirugías Artroscópicas. También contamos con una renovada y espaciosa sala de recuperación con capacidad para 3 pacientes.',
    servi:[],
    imagen: 'assets/img/QUIROFANO445X351.jpg',
    descipcionResumen: 'Cuenta con 3 quirófanos dotados con equipo moderno y tecnología de punta.',
    icono: 'icofont-operation-theater',
  },
  {
    id: 22,
    nombre: 'UNIDAD DE CIRUGÍA AMBULATORIA',
    descripcion: 'La cirugía ambulatoria se realiza cada vez con mayor frecuencia debido a sus importantes beneficios, como menores costos y menor morbimortalidad, y requiere una cuidadosa selección de pacientes y procedimientos quirúrgicos a realizar.',
    servi:[],
    imagen: 'assets/img/CIRUGIAAMBULATORIA445X351.jpg',
    descipcionResumen: 'Importantes beneficios, como menores costos y menor morbimortalidad,',
    icono: 'icofont-first-aid',
  },
  {
    id: 23,
    nombre: 'UNIDAD DE ENFERMEDADES INFECCIOSAS',
    descripcion: 'Con el objetivo de proporcionar soporte hospitalario para el tratamiento adecuado de las patologías infecciosas, oncológicas e Inmunosupresoras.',
    servi:[],
    imagen: 'assets/img/ENFERMEDADESINFECCIOSAS445X351.jpg',
    descipcionResumen: 'Patologías oncológicas e Inmunosupresoras.',
    icono: 'icofont-blood-drop',
  }
 

  ]
  constructor() { }
}
