<div class="header-top d-none d-lg-block">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-sm-9 col-lg-9">
                <div class="header-top-item">
                    <div class="header-top-left">
                        <ul>
                            <li><a href="tel:+5042606-0024"><i class="icofont-phone"></i> +504 2606-0024</a></li>
                            <li><a href="mailto:servicioalcliente@llmc.hn"><i class="icofont-ui-message"></i>
                                    servicioalcliente@llmc.hn</a>
                            </li>
                            <li><a target="_blank" href="https://goo.gl/maps/x5zoixSRxDdub4gL8"><i
                                        class="icofont-location-pin"></i> La Lima Cortes, Frente a Zona Americana </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3">
                <div class="header-top-item">
                    <div class="header-top-right">
                        <ul>
                            <li><a href="https://www.facebook.com/hospital.llmc" target="_blank"><i
                                        class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/hospitallmc/" target="_blank"><i
                                        class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UCYAQjL31xy9jhCWHlA7uvsA/" target="_blank"><i
                                        class="fab fa-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="navbar-area sticky-top">
    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img class="logoImg" src="assets/img/LOGOCOLOR.png"
                        alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Inicio</a></li>
                        <li class="nav-item"><a routerLink="/sobrenosotros" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Sobre nosotros</a></li>
                        <!-- <li class="nav-item"><a routerLink="#" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Áreas</a></li> -->
                        <li class="nav-item"><a routerLink="/servicios" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Servicios</a></li>
                        <li class="nav-item"><a routerLink="/medicos" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Médicos</a></li>
                        <li class="nav-item"><a routerLink="/aseguradoras" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Aseguradoras</a></li>
                        <li class="nav-item"><a routerLink="/UEI" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">UEI</a></li>
                        <li class="nav-item"><a routerLink="/contacto" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Contacto</a></li>



                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Inicio</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Home Page 1</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Page
                                        2</a></li>

                                <li class="nav-item"><a routerLink="/home-three" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home Page
                                        3</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">About</a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/appointment" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Appointment</a></li>

                                <li class="nav-item"><a routerLink="/departments" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Departments</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item"><a routerLink="/login" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Login</a></li>

                                <li class="nav-item"><a routerLink="/signup" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Privacy
                                        Policy</a></li>

                                <li class="nav-item"><a routerLink="/terms-condition" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Terms &
                                        Conditions</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Coming
                                        Soon</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Services</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/services" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services</a>
                                </li>

                                <li class="nav-item"><a routerLink="/services-details" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services
                                        Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Doctor</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/doctor" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Doctor</a></li>

                                <li class="nav-item"><a routerLink="/doctor-details" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Doctor
                                        Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog
                                        Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Contact</a>
                        </li> -->
                    </ul>

                    <!-- <div class="nav-srh">
                        <div class="search-toggle">
                            <button class="search-icon icon-search"><i class="icofont-search-1"></i></button>
                            <button class="search-icon icon-close"><i class="icofont-close"></i></button>
                        </div>
                        
                        <div class="search-area">
                            <form>
                                <input type="text" class="src-input" id="search-terms" placeholder="Search here..." />
                                <button type="submit" name="submit" value="Go" class="search-icon"><i class="icofont-search-1"></i></button>
                            </form>
                        </div>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>
</div>

<!-- Nueva nav mobile -->
<div class="nav-mobile-section sticky-top d-flex d-lg-none ">
    <div class="nav-mobile">
        <div>
            <a routerLink="/">
                <img class="logoImgMobile" src="assets/img/LOGOBLANCO.png" alt="Logo">
            </a>
        </div>
        <div>
            <button class="open-nav" (click)="navToggle()">
                <ng-container *ngIf="navOpen">
                    <!-- cambiar por un icono de cerrar -->
                   <i class="icofont-close" style="right: 0px; left: auto; text-align: center; text-indent: 0px;font-size: 20px;"></i>
                </ng-container>
                <ng-container *ngIf="!navOpen">
                    <!-- cambiar por un icono de menu -->
                    <i class="icofont-navigation-menu"style="right: 0px; left: auto; text-align: center; text-indent: 0px;font-size: 20px;"></i>
                </ng-container>
            </button>
        </div>
    </div>
    <div class="nav-options" *ngIf="navOpen">
        <div class="mobile-navbar-nav">
            <div class="mobile-nav-item">
                <a routerLink="" (click)="navToggle()" class="mobile-nav-link" routerLinkActive="mobile-nav-link-active"
                    [routerLinkActiveOptions]="{exact: true}">
                    Inicio
                </a>
            </div>
            <div class="mobile-nav-item">
                <a routerLink="sobrenosotros" (click)="navToggle()" class="mobile-nav-link"
                    routerLinkActive="mobile-nav-link-active" [routerLinkActiveOptions]="{exact: true}">
                    Sobre nosotros
                </a>
            </div>
            <div class="mobile-nav-item">
                <a routerLink="servicios" (click)="navToggle()" class="mobile-nav-link"
                    routerLinkActive="mobile-nav-link-active" [routerLinkActiveOptions]="{exact: true}">
                    Servicios
                </a>
            </div>
            <div class="mobile-nav-item">
                <a routerLink="medicos" (click)="navToggle()" class="mobile-nav-link"
                    routerLinkActive="mobile-nav-link-active" [routerLinkActiveOptions]="{exact: true}">
                    Médicos
                </a>
            </div>
            <div class="mobile-nav-item">
                <a routerLink="aseguradoras" (click)="navToggle()" class="mobile-nav-link"
                    routerLinkActive="mobile-nav-link-active" [routerLinkActiveOptions]="{exact: true}">
                    Aseguradoras
                </a>
            </div>
            <div class="mobile-nav-item">
                <a routerLink="UEI" (click)="navToggle()" class="mobile-nav-link"
                    routerLinkActive="mobile-nav-link-active" [routerLinkActiveOptions]="{exact: true}">
                    UEI
                </a>
            </div>
            <div class="mobile-nav-item">
                <a routerLink="contacto" (click)="navToggle()" class="mobile-nav-link"
                    routerLinkActive="mobile-nav-link-active" [routerLinkActiveOptions]="{exact: true}">
                    Contacto
                </a>
            </div>
        </div>
    </div>
</div>