import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicosService } from 'src/app/services/medicos.services';

@Component({
  selector: 'app-doctor-details',
  templateUrl: './doctor-details.component.html',
  styleUrls: ['./doctor-details.component.scss']
})
export class DoctorDetailsComponent implements OnInit {
  medico = null;

  constructor(private route: ActivatedRoute, private medicos: MedicosService, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      if (params) {
        this.getServiceInfo(params.get('medico'));
      } else {
        this.router.navigateByUrl("/medicos");
      }
    });

  }
  getServiceInfo(DoctorName) {
    this.medico = this.medicos.Medicos.filter(i => i.nombre === DoctorName)[0];

    if (this.medico === null || this.medico === undefined) {
      this.router.navigateByUrl("/medicos");
    }
  }

}
