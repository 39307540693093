import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MailService } from 'src/app/services/mail.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  loading = false;
  contactForm: FormGroup;
  nombre = new FormControl('', [Validators.required]);
  correo = new FormControl('', [Validators.required]);
  telefono = new FormControl('', [Validators.required]);
  asunto = new FormControl('', [Validators.required]);
  mensaje = new FormControl('', [Validators.required]);

  constructor(private mail: MailService) { }

  ngOnInit(): void {
    this.contactForm = new FormGroup({
      nombre: this.nombre,
      correo: this.correo,
      telefono: this.telefono,
      asunto: this.asunto,
      mensaje: this.mensaje
    })
  }

  async sendMail(){
    this.loading=true;
    if(this.contactForm.valid){
      try{
        await this.mail.sendMail(this.contactForm.value);
        alert("Muchas gracias hemos recibido tu mensaje, en un lapso de 24 horas un asesor de servicio al cliente te contactara.");
        this.contactForm.reset();
      } catch(error){
        alert("Muchas gracias hemos recibido tu mensaje, en un lapso de 24 horas un asesor de servicio al cliente te contactara.");
        this.contactForm.reset();
      }
      
     
      
    } else{
      alert("Debe llenar todos los campos.");
    }

    this.loading=false;
    
  }

}
