<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Servicios</h2>
                <ul>
                    <li><a routerLink="/">Inicio</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Servicios</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="row">


            <div *ngFor="let servicio of sevicios.Servicios" class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i [class]="servicio.icono"></i>
                        <h3>{{servicio?.nombre}}</h3>
                        <p>{{servicio?.descipcionResumen}} </p>
                    </div>

                    <div class="service-end">
                        <i [class]="servicio.icono"></i>
                        <h3>{{servicio?.nombre}}</h3>
                        <p>{{servicio?.descipcionResumen}} </p>
                        <a  [routerLink]="['/services-details']" [queryParams]="{servicio: servicio.nombre}" >Leer más</a>
                    </div>
                </div>
            </div>

     
            
        </div>
    </div>
</section>

<!-- <section class="expertise-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nuestra Experiencia</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="expertise-item">
                    <div class="row">
                        <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                            <a  [routerLink]="['/services-details']" [queryParams]="{servicio: 'LABORATORIO'}">
                                <div class="expertise-inner">
                                    <i class="icofont-microscope"></i>
                                    <h3>Laboratorio</h3>
                                    <p>Abierto las 24 horas y los 365 días del año.</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                            <a  [routerLink]="['/services-details']" [queryParams]="{servicio: 'RADIOLOGÍA'}">
                                <div class="expertise-inner">
                                    <i class="icofont-xray"></i>
                                    <h3>Radiología</h3>
                                    <p>Tecnología de punta a tu alcance.</p>
                                </div>
                            </a>
                        </div>

                        <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                            <a  [routerLink]="['/services-details']" [queryParams]="{servicio: 'HOSPIPLAN'}">
                                <div class="expertise-inner">
                                    <i class="icofont-first-aid"></i>
                                    <h3>HospiPlan</h3>
                                    <p>Salud y protección para toda la familia.</p>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                            <a  routerLink="/cio5">
                                <div class="expertise-inner">
                                    <i class="icofont-runner-alt-2"></i>
                                    <h3>CIO5</h3>
                                    <p>Pierde peso y gana vida con los expertos.</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 ">
                <div class="expertise-item">
                    <div class="expertise-right ">
                        <img src="assets/img/home-one/EXP.jpg" alt="Expertise">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- 
<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest Blogs</h2>
        </div>
        
        <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg11.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">In this hospital there are special surgeon.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg12.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">World AIDS Day, designated on 1 December.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg13.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">More than 80 clinical trials launch to test coronavirus.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->