import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  navOpen = false;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth > 991) {
      this.navOpen = false;
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

  navToggle() {
    this.navOpen = !this.navOpen;
  }

}
