<div class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-item">
                    <h1>Centro Integral de Obesidad y Unidad de Cirugía Bariátrica</h1>
                    <p>El Hospital La Lima Medical Center preocupado por el bienestar de nuestros pacientes ha desarrollado el primer Programa Multidisciplinario en C.A </p>
<!-- 
                    <div class="common-btn-two">
                        <a routerLink="/appointment">Get Appointment</a>
                        <a class="cmn-btn-right-two" routerLink="/about">Learn More</a>
                    </div> -->

                    <div class="banner-right">
                        <img src="assets/img/home-three/cio5_blanco.png" alt="Banner">
                        <img src="assets/img/home-three/FORMA.png" alt="Banner">
                        <img src="assets/img/home-three/FORMA.png" alt="Banner"> 
                        <!-- <img src="assets/img/home-one/home-bg5.png" alt="Banner"> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="heart-shape"><img src="assets/img/home-three/3.png" alt="Banner"></div> -->
</div>
<section class="welcome-area pb-70">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-6 p-0">
                <div class="welcome-item welcome-left">
                    <img src="assets/img/ENTRADACIO5.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 p-0">
                <div class="welcome-item welcome-right">
                    <div class="section-title-two">
                        <span>Acerca de Nosotros</span>
                        <h2>Bienvenido a CIO5 Nosotros tenemos...</h2>
                    </div>

                    <ul>
                        <li class="wow fadeInUp" data-wow-delay=".3s">
                            <i class="fas fa-medal"></i>
                            <div class="welcome-inner">
                                <h3>Equipo Certificado</h3>
                                <p>El equipo multidisciplinario de CIO5 cuenta con el conocimiento y tecnología necesaria para poder entenderte, evaluarte y ayudarte.</p>
                            </div>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".3s">
                            <i class="fas fa-handshake"></i>
                            <div class="welcome-inner">
                                <h3>Experiencia</h3>
                                <p>Somos el Centro #1 de pérdida de peso en Honduras, contamos con la experiencia necesaria para acompañarte en tu proceso.</p>
                            </div>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".3s">
                            <i class="fas fa-grip-horizontal"></i>
                            <div class="welcome-inner">
                                <h3>Variedad de opciones</h3>
                                <p>Contamos con varias alternativas en salud para evaluarte y que puedas lograr tu objetivo de reducir tu peso de una manera segura.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section class=" ">
    <div class="container">
        <div class="section-title">
            <h2>Casos de Éxito</h2>
        </div>
        <section class="hospital-area ">
            <div class="container-fluid p-0">
                <!-- <div class="hospital-shape">
                    <img src="assets/img/home-three/6.png" alt="Shape">
                </div> -->
        
                <div class="row m-0 align-items-center">
                    <div class="col-lg-12">
                        <div class="hospital-item">
                            <!-- <a class="hospital-play-btn popup-youtube" href="https://www.youtube.com/watch?v=GZslHC0NOok"><i class="icofont-ui-play"></i></a> -->
                           
                            <div class="row m-0">
                                <div class="col-lg-3 p-0">
                                    <div class="hospital-left-one">
                                        <img src="assets/img/home-three/412x563KIARAANTES (1).jpg" alt="About">
                                    </div>
                                </div>
        
                                <div class="col-lg-3 p-0">
                                    <div class="hospital-left-two">
                                        <img src="assets/img/home-three/422x556KIARADESPUES (1).jpg" alt="About">
                                    </div>
                                </div>
                                <div class="col-lg-3 p-0">
                                    <div class="hospital-left-one">
                                        <img src="assets/img/home-three/412x563PX2ANTES.jpg" alt="About">
                                    </div>
                                </div>
        
                                <div class="col-lg-3 p-0">
                                    <div class="hospital-left-two">
                                        <img src="assets/img/home-three/422x556xPX2DESPUES.jpg" alt="About">
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
        
                    <!-- <div class="col-lg-6">
                        <div class="hospital-item hospital-right">
                            <h2>Cirugía Bariátrica</h2>
                            <p>Somos #1 en Honduras en Cirugía Bariátrica</p>
                            <ul>
                                <li><i class="icofont-check-circled"></i> Pierde Peso</li>
                                <li><i class="icofont-check-circled"></i> Gana Vida</li>
                                <li><i class="icofont-check-circled"></i> Con los Expertos</li>
                            </ul>
                            <a class="hospital-btn" routerLink="/about">Know More</a> 
                        </div>
                    </div> -->
                </div>
            </div>
        </section>
    </div>
</section>  


<section class="speciality-area ">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-12">
                <div class="speciality-left">
                    <div class="section-title-two">
                        <span>Especialidad</span>
                        <h2>Cirugia Bariátrica</h2>
                    </div>

                    <div class="speciality-item">
                        <div class="row m-0">
                            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-ui-cut"></i>
                                    <h3>Baypass Gástrico</h3>
                                    <p>Consiste en hacer el estómago más pequeño creando una pequeña bolsa para restringir la ingesta de alimentos y evita un segmento del intestino delgado para obtener resultados como: Sentir saciedad con poco alimento. Absorber un menor número de calorías.</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="fas fa-crop-alt"></i>
                                    <h3>Manga Gástrica</h3>
                                    <p>Trata la obesidad que busca reducir el volumen del estómago y por tanto su capacidad. Esta técnica, conocida también como gastrectomía vertical o simplemente la “manga”, remueve del 75% a 80% del estómago para dejar un largo estómago tubular.</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="far fa-circle"></i>
                                    <h3>Balón Gástrico</h3>
                                    <p>También denominado como balón intragástrico, es una esfera de silicona flexible y blanda, que se introduce en el estómago del paciente, mediante una endoscopia, con el fin de conseguir la reducción de peso en personas con obesidad mórbida.</p>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div class="section-title-two">
                        <h2>Atenciones</h2>
                    </div>
                    <div class="speciality-item">
                        <div class="row m-0">
                            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="fas fa-apple-alt"></i>
                                    <h3>Nutrición</h3>
                                    <p>Se encarga de diagnosticar y tratar enfermedades asociadas a la nutrición. Abarca desde la desnutrición a la obesidad, sus causas, enfermedades asociadas y sus tratamientos en las distintas etapas de la vida, en CIO5 contamos con planes individuales y empresariales para poder dar seguimiento al estado de tu salud.</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="fas fa-brain"></i>
                                    <h3>Psicología</h3>
                                    <p>Profesionales de la psicología de la obesidad, se encarga de la detección, diagnóstico y tratamiento de aquellas dificultades psicológicas que pueden aparecer relacionadas con la obesidad, así como de la prevención y promoción de la salud de conductas alteradas relacionadas con problemas de peso.</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-ui-cut"></i>
                                    <h3>Cirugia Bariátrica</h3>
                                    <p>Se realiza cuando la dieta y el ejercicio no han funcionado o cuando tienes problemas graves de salud debido a tu peso. Algunos procedimientos limitan la cantidad que puede comer. Otros procedimientos funcionan reduciendo la capacidad del cuerpo para absorber nutrientes. Algunos procedimientos hacen ambas cosas.</p>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-5 pr-0">
                <div class="speciality-item speciality-right">
                    <img src="assets/img/home-two/bg4.jpg" alt="Speciality">

                    <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h3>Emergency Call</h3>
                        <p>+07 554 332 322</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>

<!-- <section class="services-area pb-70">
    <div class="container">
        <div class="section-title-two">
            <span>Services</span>
            <h2>Our Hospital Services</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Expert Doctor</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Expert Doctor</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-prescription"></i>
                        <h3>Diagnosis</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-prescription"></i>
                        <h3>Diagnosis</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-patient-file"></i>
                        <h3>Pathology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-patient-file"></i>
                        <h3>Pathology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-tooth"></i>
                        <h3>Dental Care</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-tooth"></i>
                        <h3>Dental Care</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Cardiology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Cardiology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->



<!-- <div class="video-wrap video-wrap-two">
    <div class="container-fluid p-0">
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Hospital Introduction</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>About Our Pharmacy</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Our reasearch  center and  lab </h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-icu" role="tabpanel" aria-labelledby="pills-icu-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>CCU & ICU</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-doctor" role="tabpanel" aria-labelledby="pills-doctor-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Our Doctors</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <ul class="video-nav nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item video-nav-item"><a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Hospital Introduction</a></li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Pharmacy</a></li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Reasearch & Lab</a></li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-icu-tab" data-toggle="pill" href="#pills-icu" role="tab" aria-controls="pills-icu" aria-selected="false">CCU & ICU</a></li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-doctor-tab" data-toggle="pill" href="#pills-doctor" role="tab" aria-controls="pills-doctor" aria-selected="false">Doctors</a></li>
        </ul>
    </div>
</div> -->

<!-- <section class="doctors-area pt-100">
    <div class="container">
        <div class="section-title-two">
            <span>Doctors</span>
            <h2>Meet Our Doctors </h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/home-one/doctor/1.jpg" alt="Doctor">

                        <a routerLink="/appointment">Get Appointment</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dr. Babatunde</a></h3>
                        <span>Neurosurgeon</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/home-one/doctor/2.jpg" alt="Doctor">

                        <a routerLink="/appointment">Get Appointment</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dr. Addision Smith</a></h3>
                        <span>Neurosurgeon</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/home-one/doctor/3.jpg" alt="Doctor">

                        <a routerLink="/appointment">Get Appointment</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dr. Sarah Tylor</a></h3>
                        <span>Dental Surgeon</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="doctor-btn">
            <a routerLink="/doctor">See All</a>
        </div>
    </div>
</section> -->

<div class="counter-area counter-area-three pb-70">
    <div class="container">
        <div class="row counter-bg">
            
            <div class="col-sm-6 col-lg-4">
                <div class="counter-item">
                    <i class="icofont-people"></i>
                    <h3><span class="counter">+500</span></h3>
                    <p>Pacientes Atendidos</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="counter-item">
                    <i class="fas fa-weight"></i>
                    <h3 class="counter">+5000</h3>
                    <p>KG Reducidos</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="counter-item">
                    <i class="icofont-badge"></i>
                    <h3 class="counter">+5</h3>
                    <p>Años de Experiencia</p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="review-area pb-100">
    <div class="container">
        <div class="main">
            <div class="slider-nav">
                <div>
                    <div class="review-img">
                        <img src="assets/img/home-three/mujer.jpg" alt="Feedback">
                    </div>
                    <div class="review-details">
                        <h3>Kiara Euraque</h3>
                        <span>Paciente Bariátrica</span>
                    </div>
                </div>

                <div>
                    <div class="review-img">
                        <img src="assets/img/home-three/hombre.jpg" alt="Feedback">
                    </div>
                    <div class="review-details">
                        <h3>Fernando Vallecillo</h3>
                        <span>Paciente Bariátrico</span>
                    </div>
                </div>

                <div>
                    <div class="review-img">
                        <img src="assets/img/home-three/mujer.jpg" alt="Feedback">
                    </div>
                    <div class="review-details">
                        <h3>Denia Aguilar</h3>
                        <span>Paciente programa CIO5</span>
                    </div>
                </div>

                <div>
                    <div class="review-img">
                        <img src="assets/img/home-three/hombre.jpg" alt="Feedback">
                    </div>
                    <div class="review-details">
                        <h3>Luis Amador</h3>
                        <span>Paciente programa CIO5</span>
                    </div>
                </div>
            </div>

            <div class="slider-for">
                <div>
                    <p>"Estar en manos de Profesionales, CIO5 a cambiado mi vida, luego de mi Cirugia Bariatrica, mi salud ha mejorado bastante, me siento mas saludable y los resultados me tienen muy feliz."</p>
                </div>

                <div>
                    <p>"Luego de la Cirugia Bariatrica la perdida de peso fue muy rapida, actualmente mi peso es de 148 lbs gracias al equipo de CIO5 y el grupo de apoyo de pacientes bariatricos."</p>
                </div>
                
                <div>
                    <p>"El programa nutricional de CIO5 es muy bueno, le enseñan a uno como debe de comer de manera mas saludable y moderada, el trato es muy cordial de parte de todos los colaboradores."</p>
                </div>

                <div>
                    <p>"Durante mi etapa donde estaba perdiendo peso, estaba experimentando ansiedad porque no veia resultados rapidos, y fue alli donde la terapia psicologica me logro ayudar."</p>
                </div>
            </div>
        </div>
    </div>
</section>

    <!-- <div class="container pb-100">
       
    </div> -->
