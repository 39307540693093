<h1 mat-dialog-title>Conoce nuestras promociones</h1>
<mat-dialog-content>
    <div class="content">
        <img src="assets/img/promociones/1.jpg">
        <img src="assets/img/promociones/2.jpg">
        <img src="assets/img/promociones/3.png">
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>