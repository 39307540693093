import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private http: HttpClient) { }

  sendMail(mail: { nombre: string, correo: string, telefono: string, asunto: string, mensaje: string }) {

    let mensaje = {
      asunto: mail.asunto,
      mensaje: `
        <h2>Nuevo Mensaje de: ${mail.nombre}</h2>

        <p><strong>Correo:</strong> ${mail.correo}</p>
        <p><strong>Telefono:</strong> ${mail.telefono}</p>
        <h3>Mensaje</h3>

        <p>${mail.mensaje}</p>
      `
    }

    return this.http.post('https://llmc.hn/phpMail/sendMail.php', mensaje).toPromise();

  }
}
