<footer class="pb-70">
    <!-- <div class="newsletter-area">
        <div class="container">
            <div class="row newsletter-wrap align-items-center">
                <div class="col-lg-6">
                    <div class="newsletter-item">
                        <h2>Join Our Newsletter</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="newsletter-item">
                        <div class="newsletter-form">
                            <form class="newsletter-form">
                                <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL">
                                <button class="btn newsletter-btn" type="submit">Subscribe</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-contact">
                        <h3>Contáctanos</h3>
                        <ul>
                            <li>
                                <i class="icofont-ui-message"></i>
                                <a href="mailto:servicioalcliente@llmc.hn">servicioalcliente@llmc.hn</a>
                            </li>
                            <li>
                                <i class="icofont-phone"></i>
                                <a href="tel:+5042606-0020">+504 2606-0020</a>
                                <a href="tel:+5042606-0021">+504 2606-0021</a>
                                <a href="tel:+5042606-0022">+504 2606-0022</a>
                                <a href="tel:+5042606-0023">+504 2606-0023</a>
                                <a href="tel:+5042606-0024">+504 2606-0024</a>
                                <a href="tel:+5042606-0025">+504 2606-0025</a>
                                <a href="tel:+5042606-0026">+504 2606-0026</a>
                                <a href="tel:+5042606-0027">+504 2606-0027</a>
                                <a href="tel:+5042606-0028">+504 2606-0028</a>
                                <a href="tel:+5042606-0029">+504 2606-0029</a>
                               

                            </li>
                            <li>
                                <a target="_blank" href="https://goo.gl/maps/x5zoixSRxDdub4gL8"><i
                                    class="icofont-location-pin"></i> La Lima Cortes, Frente a Zona Americana </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Accesos Directos</h3>
                        <ul>
                            <li><a routerLink="">Inicio</a></li>
                            <li><a routerLink="sobrenosotros">Sobre Nosotros</a></li>
                            <li><a routerLink="servicios">Servicios</a></li>
                            <li><a routerLink="medicos">Médicos</a></li>
                            <!-- <li><a routerLink="cio5">CIO5</a></li> -->
                            <li><a routerLink="UEI">UEI</a></li>
                            <li><a routerLink="contacto">Contáctanos</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Nuestros Servicios</h3>
                        <ul>
                            <li> <a  [routerLink]="['/services-details']" [queryParams]="{servicio: 'RADIOLOGÍA'}" >Radiología</a></li>
                            <li> <a  [routerLink]="['/services-details']" [queryParams]="{servicio: 'LABORATORIO'}" >Laboratorio</a></li>
                            <li> <a  [routerLink]="['/services-details']" [queryParams]="{servicio: 'HOSPIPLAN'}" >Hospiplan</a></li>
                            <li> <a  [routerLink]="['/services-details']" [queryParams]="{servicio: 'SALA DE EMERGENCIA'}" >Sala de Emergencia</a></li>
                            <!-- <li> <a  routerLink="covid19" >COVID-19</a></li> -->
                        </ul>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</footer>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-item">
            <p>Copyright ©2022 La Lima Medical Center </p>
            <p>Desarrollado por <a href="https://ciatechn.com/" target="_blank">CIATEC HN</a></p>
        </div>
    </div>
</div>