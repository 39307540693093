import { Component} from '@angular/core';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent {

  constructor() { }

  ngOnInit(): void {
  }

  goto(url){
    window.open(url);
  }
}
