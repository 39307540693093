import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MedicosService {

  Medicos = [
    {
      id: 1,
      nombre: 'DR. JAIME MATUTE',
      especialidad: 'GINECOLOGO',
      // imagen: 'assets/img/medicos/GINECOLOGOMATUTE.jpg',
      horario: 'LUNES A SABADO DE 8:00 - 11:00',
      nota: 'ORDEN DE LLEGADA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la Universidad San Carlos de Guatemala',
      biografia: 'La ginecología es una especialidad de la medicina que se centra en el estudio del sistema reproductor femenino. Los profesionales que se ocupan de esta especialidad se conocen como ginecólogos, que son los especialistas que atienden todas las patologías relacionadas con los órganos femeninos como el útero, la vagina y los ovarios, y también de la prevención de enfermedades futuras.'
    },
    {
      id: 2,
      nombre: 'DR. IVAN CRUZ',
      especialidad: 'MEDICINA INTERNA',
      //imagen: 'assets/img/medicos/INTERNISTAIVAN.jpg',
      horario: 'LUNES A VIERNES',
      nota: 'PREVIA CITA 1 SEMANA ANTES',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'La especialidad de Medicina Interna se ocupa del diagnóstico y tratamiento de todas las enfermedades que pueden afectar al adulto, siempre y cuando no necesiten ser tratadas quirúrgicamente. '
    },
    {
      id: 3,
      nombre: 'DR. ANIBAL MURILLO',
      especialidad: 'MEDICINA INTERNA',
      //imagen: 'assets/img/medicos/INTERNISTAMURILLO.jpg',
      horario: 'LUNES A VIERNES 8:00 - 11:00',
      nota: 'PREVIA CITA 1 SEMANA ANTES',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'La especialidad de Medicina Interna se ocupa del diagnóstico y tratamiento de todas las enfermedades que pueden afectar al adulto, siempre y cuando no necesiten ser tratadas quirúrgicamente. '
    },
    {
      id: 4,
      nombre: 'DR. JAIME MONTECINOS',
      especialidad: 'NEUROLOGIA Y MEDICINA INTERNA',
     // imagen: 'assets/img/medicos/NEUROLOGOMONTESINOS.jpg',
      horario: 'LUNES A VIERNES 11:00 - 2:00',
      nota: 'PREVIA CITA 1 SEMANA ANTES',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la Universidad San Carlos de Guatemala',
      biografia: 'La especialidad de Medicina Interna se ocupa del diagnóstico y tratamiento de todas las enfermedades que pueden afectar al adulto, siempre y cuando no necesiten ser tratadas quirúrgicamente. La Neurología es la especialidad médica que trata los trastornos del sistema nervioso. Específicamente se ocupa de la prevención, diagnóstico, tratamiento y rehabilitación de todas las enfermedades que involucran al sistema nervioso central, sistema nervioso periférico y el sistema nervioso autónomo.'
    },
    {
      id: 5,
      nombre: 'DR. JOAQUIN GOUBAUD',
      especialidad: 'ORTOPEDIA Y TRAUMATOLOGÍA',
      //imagen: 'assets/img/medicos/ORTOPEDAJOAQUINGOUBAUD.jpg',
      horario: 'LUNES A VIERNES DE 9:00 -12:00',
      nota: 'ORDEN DE LLEGADA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la Universidad San Carlos de Guatemala',
      biografia: 'Esta especialidad es una rama de la cirugía dedicada al diagnóstico, tratamiento, rehabilitación y prevención de lesiones y enfermedades que afectan al sistema músculo-esquelético. Este complejo sistema abarca huesos, articulaciones, ligamentos, tendones, músculos y nervios.'
    },
    {
      id: 6,
      nombre: 'DRA. EMMA SALGADO',
      especialidad: 'ORTOPEDIA Y TRAUMATOLOGÍA',
     // imagen: 'assets/img/medicos/ORTOPEDAEMMASALGAADO.jpg',
      horario: 'LUNES, MIERCOLES Y VIERNES DE 9:00 - 11:30',
      nota: 'ORDEN DE LLEGADA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresada de la UNAH y Universidad de San Carlos de Guatemala',
      biografia: 'Esta especialidad es una rama de la cirugía dedicada al diagnóstico, tratamiento, rehabilitación y prevención de lesiones y enfermedades que afectan al sistema músculo-esquelético. Este complejo sistema abarca huesos, articulaciones, ligamentos, tendones, músculos y nervios.'
    },
    {
      id: 7,
      nombre: 'DRA. EMILY TROCHEZ',
      especialidad: 'PEDIATRA',
     // imagen: 'assets/img/medicos/PEDRIATRATROCHEZ.jpg',
      horario: 'LUNES A VIERNES DE 9:00 - 11:00 Y 1:30 A 3:00',
      nota: 'ORDEN DE LLEGADA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresada de la UNAH',
      biografia: 'La pediatría es la rama de la medicina que se especializa en la salud y las enfermedades de los niños. Se trata de una especialidad médica que se centra en los pacientes desde el momento del nacimiento hasta la adolescencia, sin que exista un límite preciso que determine el final de su validez.'
    },
    {
      id: 8,
      nombre: 'DR. JORGE LUIS PINEDA',
      especialidad: 'PEDIATRA',
     // imagen: 'assets/img/medicos/PEDIATRAPINEDA.jpg',
      horario: 'LUNES A VIERNES DE 9:00 - 12:00',
      nota: 'ORDEN DE LLEGADA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'La pediatría es la rama de la medicina que se especializa en la salud y las enfermedades de los niños. Se trata de una especialidad médica que se centra en los pacientes desde el momento del nacimiento hasta la adolescencia, sin que exista un límite preciso que determine el final de su validez.'
    },
    {
      id: 9,
      nombre: 'DR. SERGIO MURILLO',
      especialidad: 'CIRUGIA GENERAL Y LAPAROSCOPICA',
     // imagen: 'assets/img/medicos/CIRUJANOSERGIOMURILLO.jpg',
      horario: 'LUNES A VIERNES DE 9:00 - 10:00 AM',
      nota: 'ORDEN DE LLEGADA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'Esta especialidad médica de clase quirúrgica que abarca las operaciones del aparato digestivo; incluyendo el tracto gastrointestinal y el sistema hepato-bilio-pancreático, el sistema endocrino; incluyendo las glándulas suprarrenales, tiroides, paratiroides, mama y otras glándulas incluidas en el aparato digestivo. Asimismo incluye la reparación de hernias y eventraciones de la pared abdominal.'
    },
    {
      id: 10,
      nombre: 'DR. CARLOS CERRATO',
      especialidad: 'CIRUGÍA GENERAL LAPAROSCÓPICA Y CIRUGÍA PLÁSTICA',
      //imagen: 'assets/img/medicos/CIRUJANOPLASTICOCARLOSCERRATO.jpg',
      horario: 'MARTES A JUEVES DE 2:00 - 4:00 PM',
      nota: 'PREVIA CITA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'Esta especialidad médica se dedica a mejorar la apariencia de las personas. Es decir, no hace falta que el paciente tenga un problema de salud, lo único que importa es si hay algún aspecto físico que desea cambiar, actualmente el Dr. Cerrato lidera la Unidad de Cirugía estética y Reconstructiva de La Lima Medical Center. '
    },
    {
      id: 11,
      nombre: 'DR. OVIDEO CALDERON',
      especialidad: 'MEDICINA INTERNA, NEFROLOGÍA, DIÁLISIS Y TRASPLANTE RENAL ',
      //imagen: 'assets/img/medicos/NEFROLOGOCALDERON.jpg',
      horario: 'TERCER MIERCOLES  DE CADA MES',
      telefono: 'TEL. 2606-0020 AL 29',
      nota: 'PREVIA CITA',
      educacion: 'Egresado de la UNAH',
      biografia: 'La nefrología es la especialidad médica rama de la medicina interna que se ocupa del estudio de la estructura y la función renal, tanto en la salud como en la enfermedad, incluyendo la prevención y tratamiento de las enfermedades renales (Riñones).'
    },
    // {
    //   id: 17,
    //   nombre: 'DRA. LORENA PERDOMO',
    //   especialidad: 'GINECÓLOGA Y OBSTETRA, ESPECIALISTA EN CIRUGÍA GENITAL ESTÉTICA Y RECONSTRUCTIVA',
    //   imagen: 'assets/img/medicos/GINECOLOGAPERDOMO.jpg',
    //   horario: 'LUNES A VIERNES 8:00 A 11:00',
    //   nota: 'PREVIA CITA',
    //   educacion: 'Egresada de la Universidad de Barcelona, España',
    //   biografia: 'La ginecología estética es lo más novedoso en tratamientos de belleza para rejuvenecer las zonas íntimas. El relleno del punto G y los labios mayores de la vagina y el blanqueamiento del área anal y vaginal son los tratamientos más demandados. '
    // },
    // {
    //   id: 24,
    //   nombre: 'DR. CARLOS CRUZ CARDONA',
    //   especialidad: 'CIRUGÍA LAPAROSCOPICA Y ENDOSCOPIA GASTROINTESTINAL',
    //   //imagen: 'assets/img/medicos/ENDOSCOPISTACARLOSCRUZ.jpg',
    //   horario: 'LUNES A VIERNES DE 7:00 AM A 3:00 PM',
    //   nota: 'ORDEN DE LLEGADA',
    //   telefono: 'TEL. 2606-0020 AL 29',
    //   educacion: 'Egresado de la UNAH',
    //   biografia: 'Esta especialidad médica de clase quirúrgica que abarca las operaciones del aparato digestivo; incluyendo el tracto gastrointestinal y el sistema hepato-bilio-pancreático, el sistema endocrino; incluyendo las glándulas suprarrenales, tiroides, paratiroides, mama y otras glándulas incluidas en el aparato digestivo. Asimismo incluye la reparación de hernias y eventraciones de la pared abdominal, Actualmente el Dr. Cruz, lidera la Unidad de Endoscopia y Colonoscopia de La Lima Medical Center.'
    // },
    {
      id: 12,
      nombre: 'DR. WALTER RODRIGUEZ',
      especialidad: 'INTENSIVISTA',
      //imagen: 'assets/img/medicos/INTENSIVISTAWALTERRODRIGUEZ.jpg',
      horario: 'SOLO PACIENTES CUIDADOS INTENSIVOS',
      nota: 'SOLO POR LLAMADOS',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'Un médico intensivista se encarga de los pacientes que están en un estado delicado, por diferentes patologías médicas. Por ejemplo, se atienden las infecciones o la descompensación de enfermedades de base, pero también quirúrgicas, de cirugías extensas y complicadas.'
    },
    {
      id: 13,
      nombre: 'DR. LEONEL CAMPBELL',
      especialidad: 'MEDICINA GENERAL',
      //imagen: 'assets/img/medicos/GENERALCAMPBELL.jpg',
      horario: 'LUNES A VIERNES DE 8:00 - 12:00  Y 2:00 - 4:00',
      nota: 'ORDEN DE LLEGADA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'La Medicina General o Medicina Familiar es la disciplina médica que se encarga de mantener la salud en todos los aspectos, analizando y estudiando el cuerpo humano en forma global, actualmente el Dr. Campbell es de los médicos calificados por la Marina Mercante para dar atención a Marineros Nacionales. '
    },
    {
      id: 14,
      nombre: 'DR. ARNALDO GAMEZ',
      especialidad: 'OFTALMOLOGIA',
      //imagen: 'assets/img/medicos/OFTALMOLOGOGAMEZ.jpg',
      horario: 'MIERCOLES Y VIERNES  1:00 - 2:00',
      nota: 'ORDEN DE LLEGADA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'La oftalmología es la especialidad médica encargada del estudio y tratamiento de las enfermedades del ojo y estructuras perioculares. La visión es uno de los sentidos más preciados por ello es imprescindible garantizar un buen cuidado, tanto de forma '
    },
    {
      id: 15,
      nombre: 'DR. BYRON BU',
      especialidad: 'OTORRINOLARINGOLOGO',
      //imagen: 'assets/img/medicos/OTORRINOBU.jpg',
      horario: 'LUNES A VIERNES 8:00 A 10:00',
      nota: 'ORDEN DE LLEGADA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'Esta especialidad médico-quirúrgica que se encarga del estudio de las enfermedades del oído, tanto auditivas como del equilibrio, de las vías respiratorias superiores y parte de las inferiores (nariz, senos paranasales, faringe y laringe), también se encarga de la cirugía relacionada con la glándula tiroides.'
    },
    {
      id: 16,
      nombre: 'DRA. NELLY BONILLA',
      especialidad: 'DERMATOLOGA',
      //imagen: 'assets/img/medicos/DERMATALOGA.jpg',
      horario: 'LUNES, MIERCOLES Y VIERNES DE 2:00 A 5:30 PM',
      nota: 'PREVIA CITA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'La dermatología es una especialidad de la medicina que se ocupa del conocimiento y estudio de la piel humana y de las enfermedades que la afectan. Esta especialidad también se preocupa de la prevención de las enfermedades y de la preservación o la recuperación de la normalidad cutánea así como de la dermo-cosmética que se dedica a la higiene, a la apariencia y protección de la piel.'
    },
    
    // {
    //   id: 18,
    //   nombre: 'DRA. ANA BLANCO',
    //   especialidad: 'NUTRIOLOGA',
    //  // imagen: 'assets/img/medicos/NUTRICIONISTAANABLANCO.jpg',
    //   horario: 'LUNES A VIERNES',
    //   nota: 'PREVIA CITA',
    //   telefono: 'TEL. 2606-0020 AL 29',
    //   educacion: 'Egresado de la UNAH',
    //   biografia: 'Esta especialidad de la salud es capaz de brindar atención a diferentes sectores de la sociedad con alguna necesidad en materia de alimentación y nutrición. Actualmente la Dra. Blanco es parte del equipo multidisciplinario del Centro Integral de Obesidad y Unidad de Cirugía Bariátrica de La Lima Medical Center. '
    // },
   
    {
      id: 20,
      nombre: 'DR. OVIDEO CERNA',
      especialidad: 'CIRUGÍA BARIATRICA, GENERAL Y LAPAROSCOPICA',
     // imagen: 'assets/img/medicos/CIRUJANOOVIDIOCERNA.jpg',
      horario: 'LUNES A VIERNES 1:00 A 3:00 PM',
      nota: 'PREVIA CITA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: '',
      biografia: 'Cirugía bariátrica es el conjunto de procedimientos quirúrgicos usados para tratar la obesidad, buscando disminución del peso corporal y como alternativa al tratamiento con otros medios no quirúrgicos. Actualmente el Dr. es parte del equipo de la Unidad de Cirugía Bariátrica de La Lima Medical Center.'
    },
    
    {
      id: 21,
      nombre: 'LIC. MAURICIO VELASQUEZ',
      especialidad: 'PSICOLOGÍA',
     // imagen: 'assets/img/medicos/SICOLOGOVELASQUEZ.jpg',
      horario: 'VIERNES DE 7:00 A 9:00 AM',
      nota: 'PREVIA CITA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'Esta especialidad se encarga de la investigación de todos los factores, evaluación, diagnóstico, tratamiento y prevención que afecten a la salud mental y a la conducta adaptativa, en condiciones que puedan generar malestar subjetivo y sufrimiento al individuo humano. '
    },
    // {
    //   id: 22,
    //   nombre: 'DRA. YANITZA HERNANDEZ',
    //   especialidad: 'FISIATRA',
    //   imagen: 'assets/img/medicos/FISIATRAYANITZA.jpg',
    //   horario: 'LUNES, MIÉRCOLES Y VIERNES DE 2:00 A 5:00 PM',
    //   nota: 'PREVIA CITA',
    //   educacion: '',
    //   biografia: ''
    // },
    {
      id: 23,
      nombre: 'DRA. SANDRA M. PINEDA',
      especialidad: 'GERIATRIA Y GERONTOLOGIA',
      //imagen: 'assets/img/medicos/GERONTOLOGIASANDRAPINEDA.jpg',
      horario: 'LUNES A VIERNES DE 8:00 A 12:00 PM',
      nota: 'PREVIA CITA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'Esta especialidad de la medicina que previene, diagnostica y trata las enfermedades de la población mayor de 65 años. Por ello, los médicos geriatras conocen los cambios que se van produciendo durante el envejecimiento, las enfermedades crónicas, los síndromes geriátricos (incontinencia, demencia, delirium...) y los medicamentos y sus efectos para estas patologías.'
    },
    {
      id: 19,
      nombre: 'DR. ALVARO FUNEZ',
      especialidad: 'CIRUGÍA BARIATRICA, GENERAL Y LAPAROSCOPIA AVANZADA',
      //imagen: 'assets/img/medicos/BARITRAFUNES.jpg',
      horario: 'SÁBADOS',
      nota: 'PREVIA CITA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la Universidad  Católica de Chile e IRCAD, Brasil',
      biografia: 'Cirugía bariátrica es el conjunto de procedimientos quirúrgicos usados para tratar la obesidad, buscando disminución del peso corporal y como alternativa al tratamiento con otros medios no quirúrgicos. Actualmente el Dr. es el encargado de la Unidad de Cirugía Bariátrica de La Lima Medical Center.'
    },
    {
      id: 25,
      nombre: 'DRA. CINTHYA MOTIÑO',
      especialidad: 'ODONTOLOGA GENERAL',
     // imagen: 'assets/img/medicos/Motino.jpeg',
      horario: 'LUNES A VIERNES DE 8:30 A 4:30 PM, SABADO 8:30 A 12:30 PM',
      nota: 'ORDEN DE LLEGADA Y DOMINGOS PREVIA CITA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH-VS',
      biografia: 'Esta especialidad de la Medicina se ocupa de la prevención, el diagnóstico y tratamiento de las enfermedades que afectan a cualquier parte de la estructura mandibular: Dientes. Encías.'
    },
    {
      id: 30,
      nombre: 'DR. JUAN R. BARON',
      especialidad: 'ANGIOLOGÍA Y CIRUGÍA VASCULAR',
     // imagen: 'assets/img/medicos/ANGIOLOGOJUANRAMOSBARON.jpg',
      horario: 'LUNES, MIERCOLES Y VIERNES DE 7AM EN ADELANTE',
      nota: 'ORDEN DE LLEGADA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la Universidad Nacional Autónoma de México',
      biografia: 'Esta especialidad médico-quirúrgica dedicada al estudio, prevención, diagnóstico clínico e instrumental y tratamiento de la patología vascular, desde el año 2012 el Dr. Baron lidera la Unidad de Enfermedad Vascular Periférica y Pie Diabético en el Hospital La Lima Medical Center.'
    },
    {
      id: 26,
      nombre: 'DR. JAVIER O. REYES',
      especialidad: 'GINECOLOGÍA Y OBSTETRICIA ',
     // imagen: 'assets/img/medicos/GINECOLOGOREYES.jpg',
      horario: 'LUNES A VIERNES DE 8:00 A 1:00 PM',
      nota: 'ORDEN DE LLEGADA Y SABADOS PREVIA CITA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH y de la Universidad de Chile',
      biografia: 'La ginecología es una especialidad de la medicina que se centra en el estudio del sistema reproductor femenino. Los profesionales que se ocupan de esta especialidad se conocen como ginecólogos, que son los especialistas que atienden todas las patologías relacionadas con los órganos femeninos como el útero, la vagina y los ovarios, y también de la prevención de enfermedades futuras.'
    },
    {
      id: 27,
      nombre: 'DR. SALVADOR ICTECH',
      especialidad: 'UROLOGO',
     // imagen: 'assets/img/medicos/UROLOGOSALVADORICTECH.jpg',
      horario: 'MARTES Y JUEVES',
      nota: 'PREVIA CITA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la Universidad de Barcelona, España',
      biografia: 'Esta especialidad médico-quirúrgica que se ocupa del estudio, diagnóstico y tratamiento de las patologías que afectan al aparato urinario, glándulas suprarrenales y retroperitoneo de ambos sexos, así como el aparato reproductor masculino.'
    },
    {
      id: 28,
      nombre: 'DR. EDY SOLIS',
      especialidad: 'MEDICINA GENERAL',
    //  imagen: 'assets/img/medicos/GENERALEDYSOLIS.jpg',
      horario: 'LUNES A VIERNES DE 8:00 A 12:00 PM',
      nota: 'ORDEN DE LLEGADA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'La Medicina General o Medicina Familiar es la disciplina médica que se encarga de mantener la salud en todos los aspectos, analizando y estudiando el cuerpo humano en forma global, actualmente el Dr. Solis es de los médicos calificados por la Marina Mercante para dar atención a Marineros Nacionales. '
    },
    {
      id: 29,
      nombre: 'DR. SILVIA YANES',
      especialidad: 'MEDICINA GENERAL',
     // imagen: 'assets/img/medicos/GENERALSILVIAYANES.jpg',
      horario: 'LUNES A VIERNES DE 8:00 A 10:30 AM',
      nota: 'ORDEN DE LLEGADA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'La Medicina General o Medicina Familiar es la disciplina médica que se encarga de mantener la salud en todos los aspectos, analizando y estudiando el cuerpo humano en forma global.'
    },
   
    {
      id: 31,
      nombre: 'DR. ARNOLD REYES',
      especialidad: 'ENDOCRINOLOGO',
     // imagen: 'assets/img/medicos/ENDOCRINOLOGOREYES.jpg',
      horario: 'JUEVES A PARTIR DE 2:00 PM',
      nota: 'ORDEN DE LLEGADA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'Esta especialidad estudia el sistema endocrino y las enfermedades provocadas por un funcionamiento inadecuado del mismo. Algunas de las enfermedades de las que se ocupa la endocrinología son la diabetes mellitus provocada por deficiencia de insulina o resistencia a su acción, el hipotiroidismo por déficit en la producción de hormonas tiroideas, el hipertiroidismo por excesiva producción de hormonas tiroideas y la enfermedad de Cushing debida generalmente a excesiva producción de cortisol por las glándulas suprarrenales.'
    },
    {
      id: 39,
      nombre: 'DRA. ALEXA BENAVIDES',
      especialidad: 'NEFROLOGÍA',
    //  imagen: 'assets/img/medicos/RADIOLOGOJAIMEAGUILAR.jpg',
      horario: 'MIÉRCOLES DE 2:00 P.M. – 5:00 P.M. ',
      nota: '',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'Es una subespecialidad de la radiología que se encarga de realizar procedimientos mínimamente invasivos guiados por imágenes radiológicas (angiografía), ecográficas o tomográficas (scanner), ya sea para hacer diagnóstico o bien para tratar algunas enfermedades en reemplazo de otras técnicas quirúrgicas.'
    },
    
    {
      id: 35,
      nombre: 'DR. ALEJANDRO FERNÁNDEZ',
      especialidad: 'NEUMOLOGÍA Y CARDIONEUMOLOGÍA',
    //  imagen: 'assets/img/medicos/RADIOLOGOJAIMEAGUILAR.jpg',
      horario: 'VIERNES DE 8:00 A.M. – 12:00 M',
      nota: '',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'Es una subespecialidad de la radiología que se encarga de realizar procedimientos mínimamente invasivos guiados por imágenes radiológicas (angiografía), ecográficas o tomográficas (scanner), ya sea para hacer diagnóstico o bien para tratar algunas enfermedades en reemplazo de otras técnicas quirúrgicas.'
    },
    {
      id: 36,
      nombre: 'DR. LUIS ARITA',
      especialidad: 'UROLOGÍA',
    //  imagen: 'assets/img/medicos/RADIOLOGOJAIMEAGUILAR.jpg',
      horario: 'LUNES 7:00 A.M. – 9:30 A.M',
      nota: '',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'Es una subespecialidad de la radiología que se encarga de realizar procedimientos mínimamente invasivos guiados por imágenes radiológicas (angiografía), ecográficas o tomográficas (scanner), ya sea para hacer diagnóstico o bien para tratar algunas enfermedades en reemplazo de otras técnicas quirúrgicas.'
    },
    {
      id: 40,
      nombre: 'DR. CRISTIAN ALVARADO',
      especialidad: 'HEMATOLOGÍA',
    //  imagen: 'assets/img/medicos/RADIOLOGOJAIMEAGUILAR.jpg',
      horario: 'VIERNES 9:00 A.M. – 12:0 M.',
      nota: '',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'Es una subespecialidad de la radiología que se encarga de realizar procedimientos mínimamente invasivos guiados por imágenes radiológicas (angiografía), ecográficas o tomográficas (scanner), ya sea para hacer diagnóstico o bien para tratar algunas enfermedades en reemplazo de otras técnicas quirúrgicas.'
    },
    
    {
      id: 37,
      nombre: 'DRA. MARIELA MALDONADO',
      especialidad: 'REUMATOLOGÍA',
    //  imagen: 'assets/img/medicos/RADIOLOGOJAIMEAGUILAR.jpg',
      horario: 'MARTES, MIÉRCOLES Y JUEVES DE 7:00 A.M. – 8:00 A.M',
      nota: '',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'Es una subespecialidad de la radiología que se encarga de realizar procedimientos mínimamente invasivos guiados por imágenes radiológicas (angiografía), ecográficas o tomográficas (scanner), ya sea para hacer diagnóstico o bien para tratar algunas enfermedades en reemplazo de otras técnicas quirúrgicas.'
    },
    {
      id: 38,
      nombre: 'DRA. ANA BLANCO',
      especialidad: 'NUTRICIONISTA',
    //  imagen: 'assets/img/medicos/RADIOLOGOJAIMEAGUILAR.jpg',
      horario: 'MIÉRCOLES DE 12:30 M. – 3:00 P.M. Y SÁBADOS DE 8:00 A.M. – 10:00 A.M.',
      nota: '',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'Es una subespecialidad de la radiología que se encarga de realizar procedimientos mínimamente invasivos guiados por imágenes radiológicas (angiografía), ecográficas o tomográficas (scanner), ya sea para hacer diagnóstico o bien para tratar algunas enfermedades en reemplazo de otras técnicas quirúrgicas.'
    },
    // {
    //   id: 33,
    //   nombre: 'DR. CESAR MANDUJANO',
    //   especialidad: 'ANESTESIOLOGÍA',
    // //  imagen: 'assets/img/medicos/ANESTEESIOLOGOCESARMANDUJANO.jpg',
    //   horario: '',
    //   nota: '',
    //   telefono: 'TEL. 2606-0020 AL 29',
    //   educacion: 'Egresado de la Universidad de San Carlos de Guatemala',
    //   biografia: 'Esta especialidad médica dedicada a la atención y cuidados especiales de los pacientes durante las intervenciones quirúrgicas u otros procesos que puedan resultar molestos o dolorosos (endoscopia, radiología intervencionista, etc.). Asimismo, tiene a su cargo el tratamiento del dolor agudo o crónico de causa extra quirúrgica.'
    // },
    {
      id: 32,
      nombre: 'DR. DENNYS F. ANDINO',
      especialidad: 'ORTOPEDIA, CIRUGÍA ARTOSCÓPICA Y REEMPLAZO ARTICULAR',
    //  imagen: 'assets/img/medicos/ARTROSCOPISTAANDINO.jpg',
      horario: 'JUEVES',
      nota: 'CITA PREVIA',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'Esta especialidad es una rama de la cirugía dedicada al diagnóstico, tratamiento, rehabilitación y prevención de lesiones y enfermedades que afectan al sistema músculo-esquelético. Este complejo sistema abarca huesos, articulaciones, ligamentos, tendones, músculos y nervios.'
    },
    {
      id: 34,
      nombre: 'DR. JAIME AGUILAR',
      especialidad: 'RADIOLOGÍA INTERVENCIONAL',
    //  imagen: 'assets/img/medicos/RADIOLOGOJAIMEAGUILAR.jpg',
      horario: '',
      nota: '',
      telefono: 'TEL. 2606-0020 AL 29',
      educacion: 'Egresado de la UNAH',
      biografia: 'Es una subespecialidad de la radiología que se encarga de realizar procedimientos mínimamente invasivos guiados por imágenes radiológicas (angiografía), ecográficas o tomográficas (scanner), ya sea para hacer diagnóstico o bien para tratar algunas enfermedades en reemplazo de otras técnicas quirúrgicas.'
    }
    
    
   
]


  constructor() { }
}
