<div class="signup-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 pl-0">
                <div class="login-left">
                    <img src="assets/img/login-bg.jpg" alt="Login">
                </div>
            </div>

            <div class="col-lg-6 ptb-100">
                <div class="signup-item">
                    <div class="signup-head">
                        <h2>Login Here</h2>
                        <p>Didn't you account yet? <a routerLink="/signup">Register Here</a></p>
                    </div>

                    <div class="signup-form">
                        <form>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="First Name">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Your Email">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="password" class="form-control" placeholder="Password">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="password" class="form-control" placeholder="Confirm Password">
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <div class="forgot-pass">
                                            <a href="#">Forgot Password?</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="text-center">
                                        <button type="submit" class="btn signup-btn">Login</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest Blogs</h2>
        </div>
        
        <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg11.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">In this hospital there are special surgeon.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg12.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">World AIDS Day, designated on 1 December.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg13.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">More than 80 clinical trials launch to test coronavirus.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>