<section class="error-area">
    <div class="error-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="error-text">
                    <h1>404!</h1>
                    <p>¡Ups! Página no encontrada</p>
                    <span>La página que estas buscando no existe aún,  pronto estara disponible.</span>
                    <a routerLink="/">Regresar al incio</a>
                </div>
            </div>
        </div>
    </div>
</section>