<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item" >
                
                <h2>Sobre Nosotros</h2>
                <ul>
                    <li><a routerLink="/">Inicio</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Sobre Nosotros</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img src="assets/img/home-one/SobreNosotros.jpg" alt="About">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    <img src="assets/img/home-one/home-bg5.png" alt="About">
                    <h2>Sobre Nosotros</h2>
                    <p>Inaugurado en 1951 por la Tela Railroad Company para brindar atención médica a sus empleados y
                        dependientes, fue adquirido a mediados del año 2003 por Hospital La Lima, S.A. de C.V. subsidiaria de
                        Hospitales de Honduras con el fin de brindar una nueva alternativa en salud privada para la Costa Norte
                        del país, cambiando su denominación social desde entonces a LA LIMA MEDICAL CENTER.<br><br>
                        Desde sus inicios como hospital privado, La Lima Medical Center ha sido sometido a constantes
                        remodelaciones (por sobre los Tres Millones de dólares), modificándose por completo toda la
                        arquitectura interior del inmueble, sin alterar la magnificencia y belleza de la arquitectura externa,
                        construyéndose 58 habitaciones privadas y 4 suites con todas las comodidades, y adquiriéndose además
                        nuevos equipos con tecnología de punta.<br><br>
                        Su renombrado equipo de profesionales Médicos, dotados de la más moderna tecnología en salud, y de
                        personal altamente calificado en las diferentes áreas, les ha permitido crecer en forma acelerada, y
                        constituir en uno de los cinco mejores hospitales del país, con precios razonables y atención
                        personalizada.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="counter-area counter-bg counter-area-four">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-patient-bed"></i>
                    <h3 class="counter">+100</h3>
                    <p>Camas</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-like"></i>
                    <h3><span class="counter">100%</span></h3>
                    <p>Calidad Garantizada</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-doctor-alt"></i>
                    <h3 class="counter">+150</h3>
                    <p>Doctores y Enfermeras</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-badge"></i>
                    <h3 class="counter">70</h3>
                    <p>Años de Experiencia</p>
                </div>
            </div>
        </div>
    </div>
</div> -->
<section class="hospital-area pb-5">
    <div class="container">
        <div class="hospital-shape">
            <img src="assets/img/home-three/6.png" alt="Shape">
        </div>
        <div class="privacy-item">
            <div class="section-title">
            <h2>Nuestra Misión</h2>
        </div>
            <p>Brindar servicios médicos y hospitalarios de excelente calidad, garantizando la atención, seguridad, confianza y respeto por la vida, valorando la individualidad de nuestros pacientes y trabajando en equipo para hacerles sentir como en casa, comprometiéndonos con el desarrollo del país, la comunidad y la conservación del medio ambiente.  </p>
        </div>
        <div class="privacy-item">
            <div class="section-title">
            <h2>Nuestra Visión</h2>
            </div>
            <p>Seguir ocupando el primer lugar en servicios médico-hospitalarios especialistas, ofreciendo el valor genuino y tranquilidad a sus pacientes para ser representativos de confianza y seguridad alcanzando el mas alto nivel de salud a lo largo de su vida. </p>
            
        </div>
       
    </div>
</section>

<section class="expertise-area ">
    <div class="container">
        <div class="row align-items-center">
            <div class="section-title">
                <h2>Nuestros Valores</h2>
            </div>
            <div class="col-lg-12 expertise-item">
                
                <div class="row">
                    <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".3s">
                        
                            <div class="expertise-inner">
                                <i class="fas fa-hands-helping"></i>
                                <h3>Respeto</h3>
                                <p>El valorar al otro, reconocer en el sus cualidades y aceptarlo como es. El valor indispensable para la convivencia y el trabajo en equipo.</p>
                            </div>
                        
                    </div>

                    <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".5s">
                        
                            <div class="expertise-inner">
                                <i class="fas fa-pray"></i>
                                <h3>Espiritualidad</h3>
                                <p>Elemento esencial de trascendencia que alimenta el Espíritu fortaleciéndolo para superar dificultades y dar sentido a la vida.</p>
                            </div>
                      
                    </div>

                    <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".3s">
                       
                            <div class="expertise-inner">
                                <i class="fas fa-hand-holding-heart"></i>
                                <h3>Honestidad</h3>
                                <p>La honestidad es el valor que expresa la transparencia de vida, la rectitud en el actuar y lleva la credibilidad de los demás.</p>
                            </div>
                        
                    </div>

                    <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".5s">
                       
                            <div class="expertise-inner">
                                <i class="fas fa-check"></i>
                                <h3>Ética</h3>
                                <p>Es la manera de actuar al ser coherente, racional y recto para llevar a cabo lo bueno. El darnos a valer y a respetar ante lo incorrecto.</p>
                            </div>
                       
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-6 expertise-item">
                <div class="expertise-right">
                    <img src="assets/img/home-one/home-bg6.jpg" alt="Expertise">
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- 
<section class="testimonial-area ptb-100">
    <div class="container">
        <div class="testimonial-wrap">
            <h2>Alianzas con Aseguradoras</h2>
            
            <div class="testimonial-slider owl-theme owl-carousel">
                <div class="testimonial-item">
                    <p>El Hospital La Lima Medical Center, es una de las mejores opciones para quienes cuentan con seguros de cobertura de gastos médicos, actualmente se han logrado importantes alianzas con empresas aseguradoras Nacionales e Internacionales.</p>
                </div>

            </div>
        </div>
    </div>
</section> -->
<div class="container pb-5">

</div>
<!-- 
<section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-7">
                <div class="speciality-left">
                    <div class="section-title-two">
                        <span>Especialidades</span>
                        <h2>Nuestras Fortalezas</h2>
                    </div>

                    <div class="speciality-item">
                        <div class="row m-0">
                            <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>COVID-19</h3>
                                    <p>La unidad más grande de Aislamiento de COVID-19</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Cirugía</h3>
                                    <p>Especialistas en Cirugía Bariática, Baypass y Manga Gástrica.</p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Seafarers</h3>
                                    <p>Una novedosa área de Preembarque a Marinos </p>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                                <div class="speciality-inner">
                                    <i class="icofont-check-circled"></i>
                                    <h3>Pie Diabético</h3>
                                    <p>Unidad de Enfermedad Vascular Periférica y Pié Diabético</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="speciality-item speciality-right">
                    <img src="assets/img/home-two/bg4.jpg" alt="Speciality">

                    <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h3>Emergencia</h3>
                        <P>+504 2606-0024 </P>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- 
<section class="services-area pb-70">
    <div class="container">
        <div class="section-title-two">
            <span>Services</span>
            <h2>Our Hospital Services</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Expert Doctor</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Expert Doctor</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-prescription"></i>
                        <h3>Diagnosis</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-prescription"></i>
                        <h3>Diagnosis</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-patient-file"></i>
                        <h3>Pathology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-patient-file"></i>
                        <h3>Pathology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-tooth"></i>
                        <h3>Dental Care</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-tooth"></i>
                        <h3>Dental Care</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Cardiology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Cardiology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-drug"></i>
                        <h3>Medicine</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonial-area ptb-100">
    <div class="container">
        <div class="testimonial-wrap">
            <h2>What our patient say</h2>
            
            <div class="testimonial-slider owl-theme owl-carousel">
                <div class="testimonial-item">
                    <img src="assets/img/home-three/7.png" alt="Testimonial">
                    <h3>John Mic</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam id labore vitae cupiditate dolorum voluptates dicta delectus quibusdam excepturi, veniam deserunt magni ipsam mollitia optio, quas quis rem non reprehenderit.</p>
                </div>

                <div class="testimonial-item">
                    <img src="assets/img/home-three/8.png" alt="Testimonial">
                    <h3>Adam Smith</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam id labore vitae cupiditate dolorum voluptates dicta delectus quibusdam excepturi, veniam deserunt magni ipsam mollitia optio, quas quis rem non reprehenderit.</p>
                </div>

                <div class="testimonial-item">
                    <img src="assets/img/home-three/9.png" alt="Testimonial">
                    <h3>Jac Jacson</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam id labore vitae cupiditate dolorum voluptates dicta delectus quibusdam excepturi, veniam deserunt magni ipsam mollitia optio, quas quis rem non reprehenderit.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title-two">
            <span>Blogs</span>
            <h2>Our latest blogs</h2>
        </div>
        
        <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg11.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">In this hospital there are special surgeon.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg12.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">World AIDS Day, designated on 1 December.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg13.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">More than 80 clinical trials launch to test coronavirus.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->