<div class="page-title-area page-title-five">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Contáctanos</h2>
                <ul>
                    <li><a routerLink="/">Inicio</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Contáctanos</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="location-area">
    <div class="container">
        <div class="row location-wrap">
            <div class="col-sm-6 col-lg-4">
                <div class="location-item">
                    <i class="icofont-location-pin"></i>
                    <h3>Dirección</h3>
                    <p><a target="_blank" href="https://goo.gl/maps/x5zoixSRxDdub4gL8"> La Lima Cortes, Frente a Zona
                            Americana </a></p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="location-item">
                    <i class="icofont-ui-message"></i>
                    <h3>Correo</h3>
                    <ul>
                        <a href="mailto:servicioalcliente@llmc.hn">servicioalcliente@llmc.hn</a>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="location-item">
                    <i class="icofont-ui-call"></i>
                    <h3>Teléfonos</h3>
                    <ul>
                        <a href="tel:+5042606-0020">+504 2606-0020</a>
                        <a href="tel:+5042606-0021">+504 2606-0021</a>
                        <a href="tel:+5042606-0022">+504 2606-0022</a>
                        <a href="tel:+5042606-0023">+504 2606-0023</a>
                        <a href="tel:+5042606-0024">+504 2606-0024</a>
                        <a href="tel:+5042606-0025">+504 2606-0025</a>
                        <a href="tel:+5042606-0026">+504 2606-0026</a>
                        <a href="tel:+5042606-0027">+504 2606-0027</a>
                        <a href="tel:+5042606-0028">+504 2606-0028</a>
                        <a href="tel:+5042606-0029">+504 2606-0029</a>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="drop-area pt-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 p-0">
                <div class="drop-item drop-img">
                    <div class="drop-left">
                        <h2>Para más información dejanos un mensaje</h2>

                        <form [formGroup]="contactForm" id="contactForm">
                            <div class="row">
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input formControlName="nombre" type="text" name="name" class="form-control"
                                            placeholder="Nombre">
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input formControlName="correo" type="email" name="email" class="form-control"
                                            placeholder="Correo">
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input formControlName="telefono" type="text" name="phone_number"
                                            class="form-control" placeholder="Teléfono">
                                    </div>
                                </div>

                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <input formControlName="asunto" type="text" name="msg_subject"
                                            class="form-control" placeholder="Asunto">
                                    </div>
                                </div>

                                <div class="col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <textarea formControlName="mensaje" name="message" class="form-control"
                                            cols="30" rows="5" placeholder="Tu  mensaje"></textarea>
                                    </div>
                                </div>

                                <div class="col-md-12 col-lg-12">
                                    <button [disabled]="loading" type="button" (click)="sendMail()" class="drop-btn">
                                       <span *ngIf="!loading">Enviar</span> 
                                        <div *ngIf="loading" class="fa-2x">

                                            <i class="fas fa-circle-notch fa-spin"></i>

                                        </div>

                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 p-0">
                <div class="speciality-item speciality-right speciality-right-two speciality-right-three">
                    <img src="assets/img/home-two/bg4.jpg" alt="Contact">

                    <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h3>Emergencias</h3>
                        <p>+504 2606-0024</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="contact-map-area">
    <div id="map">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15383.660405286311!2d-87.9184127!3d15.4351329!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x142fb2f428d81fd8!2sLa%20Lima%20Medical%20Center!5e0!3m2!1ses-419!2shn!4v1621289472847!5m2!1ses-419!2shn"></iframe>
    </div>
</div>