<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Alianza con aseguradoras</h2>
                <ul>
                    <li><a routerLink="/">Inicio</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Aseguradoras</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="faq-area pt-100 ">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2 class="text-center" style="color:#4a6f8a">El Hospital La Lima Medical Center es una de las mejores opciones para quieres
                        poseen un seguro con cobertura de gastos médicos, contamos con importantes
                        alianzas con aseguradoras nacionales e internacionales.</h2>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="hospital-area">
    <div class="container">
      
        <div class="hospital-shape">
                   </div>
        <div class="privacy-item">
            <div class="section-title">
            <h2>Aseguradoras Nacionales</h2>
        </div>
        
       
        </div>
       
    </div>
</section>

<section class="blog-area-two pt-30">
    
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a ><img src="assets/img/home-one/SEGUROSATLANTIDA.png" alt="Blog"></a>
                    </div>

                    <!--<div class="blog-bottom">
                         <h3><a routerLink="/blog-details">In this hospital there are special surgeon.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul> 
                    </div>-->
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a><img src="assets/img/home-one/PANAMERICANLIFE.png" alt="Blog"></a>
                    </div>

                    
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a ><img src="assets/img/home-one/BANRURAL.png" alt="Blog"></a>
                    </div>

                  
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a ><img src="assets/img/home-one/FICOHSA.png" alt="Blog"></a>
                    </div>

                  
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a ><img src="assets/img/home-one/CONTINENTAL.png" alt="Blog"></a>
                    </div>

                  
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <!-- <a routerLink="/blog-details"><img src="assets/img/home-one/DAVIVIENDA.png" alt="Blog"></a>  -->
                        <a ><img src="assets/img/home-one/DAVIVIENDA.png" alt="Blog"></a> 
                    </div>

                  
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a ><img src="assets/img/home-one/CREFISA.png" alt="Blog"></a>
                    </div>

                   
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a ><img src="assets/img/home-one/ASSA.png" alt="Blog"></a>
                    </div>

                   
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a><img src="assets/img/home-one/MAPHRE.png" alt="Blog"></a>
                    </div>

                    
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a ><img src="assets/img/home-one/EQUIDAD.png" alt="Blog"></a>
                    </div>

                   
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a ><img src="assets/img/home-one/SEGUROSDELPAIS.png" alt="Blog"></a>
                    </div>

                    
                </div>
            </div>
        </div>
    </div>
</section>
<section class="hospital-area">
    <div class="container">
      
        <div class="hospital-shape">
                   </div>
        <div class="privacy-item">
            <div class="section-title">
            <h2>Aseguradoras Internacionales</h2>
        </div>
        
       
        </div>
       
    </div>
</section>

<section class="blog-area-two pt-50">
    
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a ><img src="assets/img/home-one/GMMI.png" alt="Blog"></a>
                    </div>

                   
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a ><img src="assets/img/home-one/CIGNA.png" alt="Blog"></a>
                    </div>

                    
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a ><img src="assets/img/home-one/BESTDOCTORS.png" alt="Blog"></a>
                    </div>

                  
                </div>
            </div>

           
        </div>
    </div>
</section>