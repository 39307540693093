<!-- <div class="home-slider home-slider-two owl-theme owl-carousel">
    <div class="slider-item slider-item-img-two">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape">
                           <img src="assets/img/home-one/home-bg5.png" alt="Shape"> 
                        </div>
                        <h1>Nuestro personal te atenderá con todas las medidas de bioseguridad</h1>
                        <p>El Hospital La Lima Medical Center, cuenta con todas las medidas de bioseguridad, desde el momento que ingresas hasta el momento que sales para garantizar que tu salud estará siempre en buenas manos.</p>

                        <div class="common-btn">
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item slider-item-img-three">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape">
                            <img src="assets/img/home-one/home-bg5.png" alt="Shape">
                        </div>
                        <h1>Recuerda utilizar siempre la mascarilla</h1>
                        <p>Al visitarnos recuerda siempre utilizar tu mascarilla, ya que está es de las mejores medidas de prevención contra el COVID-19.</p>

                        <div class="common-btn">
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item slider-item-img-four">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape">
                            <img src="assets/img/home-one/home-bg5.png" alt="Shape">
                        </div>
                        <h1>Protege tu salud y la de tu familia</h1>
                        <p>La Lima Medical Center, es un hospital equipado con una Unidad de Aislamiento para COVID-19 con un equipo multidisciplinario para acelerar tu recuperación</p>

                        <div class="common-btn">
                         
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="home-slider owl-theme owl-carousel">
    <div class="slider-item1 slider-item-img4 backNormal">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape">
                            <!-- <img src="assets//img/home-two/slider/ENFERMERA.png" alt="Shape"> -->
                        </div>
                        <h1 style="max-width: 1000px;">Unidad de Enfermedades Infecciosas</h1>
                        <p style="max-width: 700px;">
                            Nuestro objetivo principal es proporcionar soporte hospitalario para el tratamiento adecuado de las patologías infecciosas en cuatro ámbitos fundamentales: Tratamiento de las complicaciones infecciosas del paciente hospitalizado, quirúrgico e inmunodeprimido.
                        </p>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="slider-item1 slider-item-img1 backNormal">
     <div class="d-table">
         <div class="d-table-cell">
             <div class="container">
                 <div class="slider-text">
                     <div class="slider-shape">
                         <!-- <img src="assets//img/home-two/slider/ENFERMERA.png" alt="Shape"> -->
                     </div>
                     <h1 style="max-width: 500px;">Nuestro personal te atenderá con todas las medidas de bioseguridad</h1>
                     <p style="max-width: 400px;">
                         El Hospital La Lima Medical Center, cuenta con todas las medidas de bioseguridad, desde el momento que ingresas hasta el momento que sales para garantizar que tu salud estará siempre en buenas manos.
                     </p>

                     
                 </div>
             </div>
         </div>
     </div>
     
 </div>

 <div class="slider-item1 slider-item-img2 backNormal">
     <div class="d-table">
         <div class="d-table-cell">
             <div class="container">
                 <div class="slider-text">
                     <div class="slider-shape-two">
                         <!-- <img src="assets//img/home-two/slider/730X500MASCARILLA (1).png" alt="Shape"> -->
                     </div>
                     <h1 style="max-width: 500px;">Recuerda utilizar siempre la mascarilla</h1>
                     <p style="max-width: 400px;">
                         Al visitarnos recuerda siempre utilizar tu mascarilla, ya que está es de las mejores medidas de prevención contra el COVID-19.
                     </p>

                    
                 </div>
             </div>
         </div>
     </div>
 </div>

 <div class="slider-item1 slider-item-img3 backNormal">
     <div class="d-table">
         <div class="d-table-cell">
             <div class="container">
                 <div class="slider-text">
                     <div class="slider-shape-three">
                         <!-- <img src="assets//img/home-two/slider/730X500FAMILIA1.png" alt="Shape"> -->
                     </div>
                     <h1 style="max-width: 500px;">Protege tu salud y la de tu familia</h1>
                     <p style="max-width: 400px;">La Lima Medical Center, es un hospital equipado con una Unidad de Aislamiento para COVID-19 con un equipo multidisciplinario para acelerar tu recuperación </p>

                 </div>
             </div>
         </div>
     </div>
 </div>
</div>

<!-- <div class="counter-area">
    <div class="container">
        <div class="row counter-bg">
            <div class="col-sm-6 col-lg-4">
                <div class="counter-item">
                    <i class="icofont-patient-bed"></i>
                    <h3 class="counter">+50</h3>
                    <p>Camas para pacientes Covid-19</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="counter-item">
                    <i class="icofont-people"></i>
                    <h3><span class="counter">+1000</span></h3>
                    <p>Pacientes Recuperados</p>
                </div>
            </div>

           <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-doctor-alt"></i>
                    <h3 class="counter">750</h3>
                    <p>Doctors  & Nurse</p>
                </div>
            </div> 

            <div class="col-sm-6 col-lg-4">
                <div class="counter-item">
                    <i class="icofont-badge"></i>
                    <h3 class="counter">#1</h3>
                    <p>Unidad privada de aislamiento para COVID-19 más grande del país</p>
                </div>
            </div>
        </div>
    </div>
</div> -->



<!-- <div class="emergency-area">
    <div class="container">
        <div class="row emergency-bg">
            <div class="col-sm-6 col-lg-4">
                <div class="emergency-item">
                    <i class="icofont-ui-call"></i>
                    <div class="emergency-inner">
                        <h3>Emergency Call</h3>
                        <p>+07 5554 3332 322</p>
                        <p>+07 5554 3332 322</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="emergency-item">
                    <i class="icofont-location-pin"></i>
                    <div class="emergency-inner">
                        <h3>Location</h3>
                        <p>2108-267 Road Quadra, Toronto, Canada Victiria Canada</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="emergency-item">
                    <i class="icofont-ambulance-crescent"></i>
                    <div class="emergency-inner">
                        <h3>Ambulance</h3>
                        <p>+07 5554 3332 322</p>
                        <p>+07 5554 3332 322</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<section class="welcome-area pt-100 pb-70">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-6 p-0">
                <div class="welcome-item welcome-left">
                    <img src="assets//img/home-two/nurse1.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 p-0">
                <div class="welcome-item welcome-right">
                    <div class="section-title-two">
                        <span>Cómo protegerse y proteger a los demás</span>
                        <h2>Medidas de Bioseguridad</h2>
                    </div>

                    <ul>
                        <li class="wow fadeInUp" data-wow-delay=".3s">
                            <i class="fas fa-head-side-mask"></i>
                            <div class="welcome-inner">
                                <h3>Use una mascarilla</h3>
                                <p>Use una mascarilla que le cubra la nariz y la boca para protegerse y proteger a los demás.</p>
                            </div>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".5s">
                            <i class="fas fa-people-arrows"></i>
                            <div class="welcome-inner">
                                <h3>Mantenga la distancia</h3>
                                <p>Manténgase a 1.5 metros de distancia de las personas que no viven con usted.</p>
                            </div>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".7s">
                            <i class="fas fa-hands-wash"></i>
                            <div class="welcome-inner">
                                <h3>Lave y desinfecte sus manos</h3>
                                <p>Lávese las manos frecuentemente con agua y jabón. Use desinfectante de manos si no dispone de agua y jabón.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="speciality-area pb-100">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-7">
                <div class="speciality-left">
                    <div class="section-title-two">
                        <span>Ponemos a tu disposión las siguientes</span>
                        <h2>Pruebas Diagnósticas de COVID-19</h2>
                    </div>

                    <div class="speciality-item">
                        <div class="row m-0">
                            <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="fas fa-vial"></i>
                                    <h3>Prueba Rápida COVID-19</h3>
                                    <p>Es una prueba de laboratorio que puede identificar el virus que provoca la COVID-19 en muestras de sangre, mediante la detección de anticuerpos.</p>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="fas fa-eye-dropper"></i>
                                    <h3>Prueba PCR para COVID-19</h3>
                                    <p>Esta prueba detecta el material genético del virus de la COVID-19 usando una técnica de laboratorio llamada reacción en cadena de la polimerasa (RCP).</p>
                                </div>
                            </div>
                            

                            <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="fas fa-head-side-cough"></i>
                                    <h3>Hisopado Antigenos COVID-19</h3>
                                    <p>Un hisopado nasal es una prueba para identificar virus y bacterias que causan infecciones respiratorias.</p>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                                <div class="speciality-inner">
                                    <i class="fas fa-vials"></i>
                                    <h3>Elisa Anticuerpos IgG-IgM</h3>
                                    <p>ELISA permite detectar la presencia de anticuerpos en la sangre de los pacientes con una elevada precisión.</p>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="speciality-item speciality-right">
                    <img src="assets/img/home-two/bg4.jpg" alt="Speciality">

                    <div class="speciality-emergency">
                        <div class="speciality-icon">
                            <i class="icofont-ui-call"></i>
                        </div>
                        <h3>Emergencia</h3>
                        <P>+504 2606-0024</P>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area ">
    <div class="container">
        <div class="section-title-two">
            <span>#QuédateEnCasa</span>
            <h2>¿A quiénes afecta más la COVID-19?</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="fas fa-blind"></i>
                        <h3>Tercera Edad</h3>
                        <p>A todas las personas que pertenecen a la tercera edad</p>
                    </div>

                    <div class="service-end">
                        <i class="fas fa-blind"></i>
                        <h3>Tercera Edad</h3>
                        <p>A todas las personas que pertenecen a la tercera edad</p>
                        <!-- <a routerLink="/services-details">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="fas fa-female"></i>
                        <h3>Embarazadas</h3>
                        <p>A todas las mujeres que estén embarazadas</p>
                    </div>

                    <div class="service-end">
                        <i class="fas fa-female"></i>
                        <h3>Embarazadas</h3>
                        <p>A todas las mujeres que estén embarazadas</p>
                        <!-- <a routerLink="/services-details">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-lungs"></i>
                        <h3>Personas con asma</h3>
                        <p>A las personas asmaticas como enfermedad preexistente</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-lungs"></i>
                        <h3>Personas con asma</h3>
                        <p>A las personas asmaticas como enfermedad preexistente</p>
                        <!-- <a routerLink="/services-details">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-blood-drop"></i>
                        <h3>Personas con diabetes e hipertensión</h3>
                        <p>A las personas con diabetes e hipertención como enfermedad preexistente</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-blood-drop"></i>
                        <h3>Personas con diabetes e hipertensión</h3>
                        <p>A las personas con diabetes e hipertención como enfermedad preexistente</p>
                        <!-- <a routerLink="/services-details">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-aids"></i>
                        <h3>Personas con cáncer</h3>
                        <p>A las personas que padezcan de cualquier tipo de cáncer como enfermedad preexistente</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-aids"></i>
                        <h3>Personas con cáncer</h3>
                        <p>A las personas que padezcan de cualquier tipo de cáncer como enfermedad preexistente</p>
                        <!-- <a routerLink="/services-details">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Personas con problemas cardíacos</h3>
                        <p>A las personas que cuenten con problemas cardíacos</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-heart-beat-alt"></i>
                        <h3>Personas con problemas cardíacos</h3>
                        <p>A las personas que cuenten con problemas cardíacos</p>
                        <!-- <a routerLink="/services-details">Read More</a> -->
                    </div>
                </div>
            </div>

            <!-- <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-dna-alt-1"></i>
                        <h3>Neurology</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    </div>

                    <div class="service-end">
                        <i class="icofont-ambulance-cross"></i>
                        <h3>Ambulance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                        <a routerLink="/services-details">Read More</a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section> 

<!-- <div class="video-wrap">
    <div class="container-fluid p-0">
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Hospital Introduction</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>About Our Pharmacy</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Our reasearch  center and  lab </h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-icu" role="tabpanel" aria-labelledby="pills-icu-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>CCU & ICU</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-doctor" role="tabpanel" aria-labelledby="pills-doctor-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=0O2aH4XLbto" class="popup-youtube"><i class="icofont-ui-play"></i></a>

                                    <div class="video-content">
                                        <h3>Our Doctors</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <ul class="video-nav nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item video-nav-item"><a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Hospital Introduction</a></li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Pharmacy</a></li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Reasearch & Lab</a></li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-icu-tab" data-toggle="pill" href="#pills-icu" role="tab" aria-controls="pills-icu" aria-selected="false">CCU & ICU</a></li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-doctor-tab" data-toggle="pill" href="#pills-doctor" role="tab" aria-controls="pills-doctor" aria-selected="false">Doctors</a></li>
        </ul>
    </div>
</div> -->
<!-- 
<section class="doctors-area pt-100">
    <div class="container">
        <div class="section-title-two">
            <span>Doctors</span>
            <h2>Meet Our Doctors </h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/home-one/doctor/1.jpg" alt="Doctor">

                        <a routerLink="/appointment">Get Appointment</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dr. Babatunde</a></h3>
                        <span>Neurosurgeon</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/home-one/doctor/2.jpg" alt="Doctor">

                        <a routerLink="/appointment">Get Appointment</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dr. Addision Smith</a></h3>
                        <span>Neurosurgeon</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="doctor-item">
                    <div class="doctor-top">
                        <img src="assets/img/home-one/doctor/3.jpg" alt="Doctor">

                        <a routerLink="/appointment">Get Appointment</a>
                    </div>

                    <div class="doctor-bottom">
                        <h3><a routerLink="/doctor-details">Dr. Sarah Tylor</a></h3>
                        <span>Dental Surgeon</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="doctor-btn">
            <a routerLink="/doctor">See All</a>
        </div>
    </div>
</section> -->


<div class="container pb-5">

</div>

<!-- <section class="appointment-area pb-100">
    <div class="container-fluid p-0">
        <div class="appointment-item">
            <h2>Book your appointment</h2>
            <span>We will confirm your  appointment within 2 hours</span>

            <div class="appointment-form">
                <form>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <i class="icofont-business-man-alt-1"></i>
                                <label>Name</label>
                                <input type="text" class="form-control" placeholder="Enter Your Name">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group">
                                <i class="icofont-ui-message"></i>
                                <label>Email</label>
                                <input type="email" class="form-control" placeholder="Enter Your Email">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group">
                                <i class="icofont-ui-call"></i>
                                <label>Phone</label>
                                <input type="text" class="form-control" placeholder="Enter Your Number">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group">
                                <i class="icofont-hospital"></i>
                                <label>Services</label>
                                <select class="form-control">
                                    <option>Dental Care</option>
                                    <option>Neurology</option>
                                    <option>Cardiology</option>
                                    <option>Pathology</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group">
                                <i class="icofont-doctor"></i>
                                <label>Doctor</label>
                                <select class="form-control">
                                    <option>Choose Your Doctor</option>
                                    <option>John Smith</option>
                                    <option>Sarah Taylor</option>
                                    <option>Stevn King</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group">
                                <i class="icofont-business-man"></i>
                                <label>Age</label>
                                <input type="text" class="form-control" placeholder="Your Age">
                            </div>
                        </div>
                    </div>

                    <div class="text-center">
                        <button type="submit" class="btn appointment-btn">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title-two">
            <span>Blogs</span>
            <h2>Our latest blogs</h2>
        </div>
        
        <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg11.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">In this hospital there are special surgeon.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg12.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">World AIDS Day, designated on 1 December.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-one/home-bg13.jpg" alt="Blog"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">More than 80 clinical trials launch to test coronavirus.</a></h3>
                        <p>Lorem ipsum is  dolor sit amet, csectetur adipiscing elit, dolore smod tempor incididunt ut labore et....</p>
                        <ul>
                            <li><a routerLink="/blog-details">Read More <i class="icofont-long-arrow-right"></i></a></li>
                            <li><i class="icofont-calendar"></i> Jan 03, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->